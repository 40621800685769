.related-article__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.related-article__wrapper .editors-pick__item {
    width: calc((100% - 16px) / 2);
    margin-bottom: 16px;
}

@media (min-width: 576px) {
  .related-article__wrapper .editors-pick__item {
    width: calc((100% - 32px) / 3);
  }
}

@media (min-width: 992px) {
    .related-article__wrapper .editors-pick__item {
        width: calc((100% - 40px) / 3);
    }
}