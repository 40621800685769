.post-block-with-breadcrumb {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.post-block-with-breadcrumb_hidden {
  width: 0;
  height: 0;
  opacity: 0;
}

/* .post-block-with-breadcrumb__img-wrapper {
  background: url("../../assets/images/banners/article.png");
} */

/* .post-block-with-breadcrumb:not(:last-child) {
  margin-bottom: 32px;
} */

.post-block-with-breadcrumb__img {
  width: 120px;
  min-width: 120px;
  margin-right: 16px;
  border-radius: 4px;
}

.post-block-with-breadcrumb__text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
}

.post-block-with-breadcrumb__link {
  color: var(--text-color);
  transition: var(--transiton);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.post-block-with-breadcrumb__link:hover {
  opacity: 0.6;
}

@media screen and (min-width: 768px) {
  .post-block-with-breadcrumb__img {
    margin-right: 24px;
    min-width: 180px;
  }

  .post-info__category-link {
    font-size: 14px;
  }

  .post-block-with-breadcrumb__text {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}
