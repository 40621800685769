.sidebar-widget__container {
  display: none;
}
.sidebar-widget__container {
  width: 300px;
  padding: 32px;
  text-align: center;
  background: var(--grey-background);
  border-radius: 0px 0px 8px 8px;
  position: relative;
  margin: auto;
}

.sidebar-widget__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: var(--line-color);
  border-radius: 8px 8px 0 0;
}

.create-account__caption {
  color: var(--caption-color);
}

.sidebar-widget__container > div {
  margin-bottom: 8px;
}

.create-account__btn-sign-up {
  background: linear-gradient(
    93.14deg,
    var(--primary-red-color) 32.01%,
    #ffac60 149.92%
  );
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  color: white;
  border: none;
  font-weight: 700;
  font-size: 1rem;
}

.create-account__btn-sign-up:hover {
  background: var(--primary-red-color);
}

.create-account-container {
  margin-bottom: 32px;
}
@media (min-width: 576px) {
  .sidebar-widget__container {
    display: block;
  }
}
