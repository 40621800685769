.error-container{
    position: relative;
}

.error-code{
    position: absolute;
    font-weight: bold;
    font-size: 200px;
    line-height: 1;
    text-align: center;
    color: #EBEFF3;
    width: 100%;
    z-index: -1;
}

.error-img__wrapper{
    padding-top: 40px;
    text-align: center;
    width: 200px;
    margin: auto;
}

.error-title{
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #636C77;
    margin-bottom: 24px;
}

.error-subtitle{
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #636C77;
    margin-bottom: 32px;
}

.btn__wrapper{
    text-align: center;
}

.red-btn{
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
    display: inline-block;
    background: #CE1818;
    border-radius: 12px;
    padding: 8px 20px;
}

.red-btn:hover{
    opacity: 0.8;
}

@media (min-width:768px) {
    .error-code{
        font-size: 400px;
        line-height: 0.8;
    }
    .error-img__wrapper{
        padding-top: 100px;
        width: 400px;
    }

    .error-title{
        font-size: 24px;
    }

    .error-subtitle{
        font-size: 16px;
        margin-bottom: 64px;
    }

    .red-btn{
        font-size: 20px;
        padding: 20px 40px;
    }
}

@media (min-width:992px) {
    .error-code{
        font-size: 500px;
        line-height: 0.8;
    }
}