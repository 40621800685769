.post-block-with-category {
  display: flex;
  align-items: flex-start;
}

.post-block-with-category:not(:last-child) {
  margin-bottom: 32px;
}

.post-block-with-category__img {
  min-width: 120px;
  width: 120px;
  border-radius: 4px;
  max-height: 70px;
}

.post-block-with-category__img-wrapper {
  margin-right: 16px;
}

.posts-container__outer-video-icons .post-block-with-category__img-wrapper {
  position: relative;
  display: flex;
}

.posts-container__outer-video-icons
  .post-block-with-category__img-wrapper:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/video-icon2.svg) no-repeat;
  width: 40px;
  height: 28px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: var(--transiton);
  background-size: contain;
}

.posts-container__outer-video-icons
  .post-block-with-category__img-wrapper:hover:after {
  opacity: 0.6;
}

.post-block-with-category__text {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.post-block-with-category__link {
  color: var(--text-color);
  transition: var(--transiton);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}

.post-block-with-category__link:hover {
  opacity: 0.6;
}

@media screen and (min-width: 768px) {
  .posts-container__outer-video-icons
    .post-block-with-category__img-wrapper:after {
    background: url(../../assets/images/play-video-icon.svg);
    width: 32px;
    height: 32px;
    background-size: contain;
  }

  .post-block-with-category:not(:last-child) {
    margin-bottom: 24px;
  }

  .post-block-with-category__img-wrapper {
    margin-right: 20px;
  }

  .post-block-with-category__img {
    min-width: 180px;
    width: 180px;
    min-height: 90px;
    object-fit: cover;
  }

  .post-block-with-category__text {
    font-size: 16px;
    line-height: initial;
    margin-bottom: 8px;
  }

  .posts-container__outer-video-icons .post-block-with-category__img {
    min-width: 156px;
    width: 156px;
  }
}

@media screen and (min-width: 992px) {
  .posts-container__outer-video-icons .post-block-with-category__img {
    min-width: 100px;
    width: 100px;
  }

  .posts-container__right .post-block-with-category__img {
    min-width: 120px;
    width: 120px;
    height: 72px;
  }
}

@media screen and (min-width: 1400px) {
  .posts-container__outer-video-icons .posts-container__left {
    max-width: 400px;
  }
  .posts-container__outer-video-icons .post-block-with-category__img {
    min-width: 156px;
    width: 156px;
  }
}
