.affiliate__item{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: solid 1px #636C77;
  padding: 10px 8px;
  margin-bottom: 5px;
}

.affiliate__image-wrapper{
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.affiliate__image{
  object-fit: contain;
  border-radius: 10px;
  border: solid 1px #636C77;
}

.affiliate_title{
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.affiliate-button{
  background: linear-gradient(93.14deg, #CE1818 32.01%, #FFE660 149.92%);
  border-radius: 8px;
  color:#fff;
  font-weight: 700;
  border: none;
  height: 32px;
}

@media (min-width: 768px) {
  .popular-Finance__item {
    padding: 8px 0 8px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }

  .popular-Finance__item-num {
    margin: auto 0px;
    font-size: 36px;
    font-family: "Open Sans";
    position: relative;
    display: inline-block;
    white-space: nowrap;
    left: -16px;
  }
}
