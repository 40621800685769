.main-post__wrapper{
    position: relative;
    width: 100%;
}

.main-post__image-wrapper{
    width: 100%;
    height: 315px;
    position: relative;
    display: block;
}

.main-post__image-wrapper::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 268px;
    bottom: 0;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(33, 41, 54, 0) 0%, rgba(33, 41, 54, 0.541667) 45.83%, var(--text-color) 100%);
}

.main-post__image-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.main-post__info-wrapper{
    padding: 0 24px;
    position: absolute;
    bottom: 29px;
}

.main-post__info-wrapper .post-info__publish-date{
    color:var(--neutral-color);
}

.main-post__info-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
    transition: var(--transiton);
}

.main-post__info-title-link {
    color: var(--frame-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

@media(min-width:576px){
    .main-post__wrapper{
        max-width: 580px;
        height: 524px;
    }

    .main-post__image-wrapper{
        height: 100%;
    }

    .main-post__info-title{
        font-size: 24px;
        line-height: 33px;  
    }

    .main-post__wrapper:hover .main-post__image-wrapper::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 268px;
        bottom: 0;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(206, 24, 24, 0) 0%, rgba(206, 24, 24, 0.24) 51.04%, #CE1818 80.73%);    
    }

    .main-post__wrapper:hover .post-info__category-link{
        color: #ffea60;
    }
    .main-post__wrapper:hover .post-info__publish-date{
        color: #fff;
    }
    
}