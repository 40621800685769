.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-top: 24px;
  /* border-top: 1px solid var(--line-color); */
  /* margin-bottom: 32px; */
}

.tags-wrapper__title {
  color: var(--caption-color);
  font-weight: 600;
  font-size: 18px;
  margin-right: 16px;
}
