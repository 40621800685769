.latest-ev-container a{
    color: rgb(206, 24, 24);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.latest-ev-container h5 {
    color: #212936;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.latest-ev-container .line-break {
    border: 1px solid #D6DEE3;
    border-bottom: none;
}

.latest-ev-container .latest-ev-item {
    gap: 24px;
    margin-bottom: 24px;
}

.latest-ev-container .latest-ev-item .latest-ev-item__img {
    cursor: pointer;
    flex: 1;
}

.latest-ev-container .latest-ev-item .latest-ev-item__img img {
    max-height: 70px;
}

.latest-ev-container .latest-ev-item .latest-ev-item__info {
    flex: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
