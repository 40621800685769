.ev-item__wrapper{
    border-radius: 8px;
    /* border: 1px solid var(--caption-color); */
    width: calc((100% - 16px) / 2);
    margin-bottom: 16px;
    overflow: hidden;
}

.ev-item__wrapper:hover{
    cursor: pointer;
    box-shadow: 4px 4px 8px 4px rgba(184, 195, 207, 0.35);
}

.ev-item__wrapper:hover .ev-item-title{
    color: var(--primary-red-color);
}

.ev-item-img__wrapper{
    margin-bottom: 8px;
    text-align: center;
    overflow: hidden;
    height: 200px;
}

.ev-item-img__wrapper img{
    height: 100%;
    object-fit: cover;
}

.ev-item-title{
    text-align: center;
    margin-bottom: 24px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 0 4px;
}

@media(min-width:768px){
    .ev-item-title{
        margin-bottom: 16px;
    }

    .ev-item__wrapper{
        width: 226px;
        height: 24  0px;
        margin-bottom: 20px;
    }

}