.faq-section__container {
  margin-bottom: 16px;
}
.faq-section__title {
   font-weight: bold;
   font-size: 24px;
   line-height: 22px;
   margin-bottom: 18px;
   text-align: center;
}
.faq-section__content .accordion {
   margin: 0 auto;
   list-style: none outside;
}
.faq-section__content .accordion > * + * {
   border-top: 1px solid white;
}
.faq-section__content .accordion-item {
   border: 1px solid var(--caption-color);
   border-radius: 8px;
   padding: 5px;
   margin-bottom: 10px;
}
.faq-section__content .accordion-item-hd {
   display: block;
   padding: 15px 30px 15px 0;
   position: relative;
   cursor: pointer;
   font-size: 18px;
   font-weight: bold;
}
.faq-section__content .accordion-item-input:checked ~ .accordion-item-bd {
   max-height: 1000px;
   padding-top: 15px;
   margin-bottom: 15px;
   -webkit-transition: max-height 1s ease-in, margin .3s ease-in, padding .3s ease-in;
   transition: max-height 1s ease-in, margin .3s ease-in, padding .3s ease-in;
}
.faq-section__content .accordion-item-input:checked ~ .accordion-item-hd > .accordion-item-hd-cta {
   -webkit-transform: rotate(-180deg);
   -ms-transform: rotate(-180deg);
   transform: rotate(-180deg);
}
.faq-section__content .accordion-item-hd-cta {
   display: block;
   width: 30px;
   position: absolute;
   top: calc(50% - 6px );
   right: 0;
   pointer-events: none;
   -webkit-transition: -webkit-transform .3s ease;
   transition: transform .3s ease;
   text-align: center;
   font-size: 12px;
   line-height: 1;
}

.faq-section__content .accordion-item-hd-cta .icon-icon-arrow-down::before{
   color: #000 !important;
}

.faq-section__content .accordion-item-bd {
   max-height: 0;
   margin-bottom: 0;
   overflow: hidden;
   -webkit-transition: max-height .15s ease-out, margin-bottom .3s ease-out, padding .3s ease-out;
   transition: max-height .15s ease-out, margin-bottom .3s ease-out, padding .3s ease-out;
}
.faq-section__content .accordion-item-input {
   clip: rect(0 0 0 0);
   width: 1px;
   height: 1px;
   margin: -1;
   overflow: hidden;
   position: absolute;
   left: -9999px;
}