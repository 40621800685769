.category-slider__wrapper {
  padding: 0 16px;
  overflow: hidden;
  width: 100%;
}

.category-slider__item {
  border-radius: 8px;
  color: var(--text-color);
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  margin-right: 16px;
  padding: 4px;
  transition: var(--transition);
  border: 1px solid var(--line-color);
  border-radius: 8px;
}

.category-slider__item:hover,
.category-slider__item.active {
  background: var(--line-color);
}

.category-slider__wrapper .slick-disabled {
  display: none !important;
}

.category-slider__wrapper .slick-next,
.category-slider__wrapper .slick-prev {
  height: 100%;
  color: black;
  z-index: 11;
  padding-top: 2px;
  top: 16px;
}

.category-slider__wrapper .slick-next {
  right: -16px;
}

.category-slider__wrapper .slick-prev {
  left: -22px;
}

.category-slider__wrapper .slick-next:before {
  content: "\e90a";
  color: #ebeff3;
  font-family: 'icomoon' !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  color: #212936;
  font-weight: 600;
  margin-left: -4px;
}

.category-slider__wrapper .slick-prev:before {
  transform: scaleX(-1);
  display: inline-block;
  content: "\e90a";
  color: #ebeff3;
  font-family: 'icomoon' !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  color: #212936;
  font-weight: 600;
  margin-right: -4px;
}

@media (min-width: 768px) {
  .category-slider__wrapper {
    margin-bottom: 0;
  }
}
