.desktop-banner__wrapper {
  display: none;
}

@media (min-width: 768px) {
  .desktop-banner__wrapper {
    display: flex;
    justify-content: center;
  }

  .desktop-banner__img {
    max-width: 100%;
  }
}
