.bottom-filter__wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
}

.category-wrapper{
    overflow: hidden;
    order: 3;
    margin-top: 24px; 
    height: 34px;
}

.category-wrapper.full-width{
    margin-top: 0; 
    width: 100%;
}

.select__wrapper.item-first{
    order: 1;
}

.select__wrapper.item-second{
    order: 2;
}

.select__wrapper.d-none{
    display: none;
}

@media(min-width:768px){
    .category-wrapper{
        width: calc((100% - (150px*2)) - 100px);
        flex: 1;
        padding-left: 8px;
    }
    .category-wrapper .slick-list{
        padding-left: 4px;
    }
    
    .category-wrapper{
        order:2;
        margin-top: 0px;
    }

    .select__wrapper.item-first{
        order: 1;
    }
    
    .select__wrapper.item-second{
        order: 3;
    }
}