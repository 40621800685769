.footer-social-icons__wrapper {
  display: flex;
  margin-bottom: 8px;
}

.footer-social-icons__link {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transiton);
}

.footer-social-icons__link span {
  font-size: 1.3rem;
}

.footer-social-icons__link:hover {
  opacity: 0.6;
}

.footer-social-icons__link:not(:last-child) {
  margin-right: 24px;
}

@media screen and (min-width: 768px) {
  .footer-social-icons__wrapper {
    flex-wrap: wrap;
    max-width: 150px;
  }

  .footer-social-icons__link {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 992px) {
  .footer-social-icons__wrapper {
    max-width: initial;
  }

  .footer-social-icons__link {
    margin-bottom: 0;
  }
}
