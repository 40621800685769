.loading-more__wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.loading-more-inner {
  color: var(--caption-color);
  padding-left: 40px;
  background: url(../../../assets/images/logo-jt.svg) no-repeat left center;
  display: inline-block;
  margin-bottom: 32px;
}
