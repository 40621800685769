.article-square__container .article-square__wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    max-width: unset;
}

.article-square__wrapper .article-square__item {
    width: calc((100% - 16px) / 2);
}

.article-square__wrapper .article-square__item:nth-of-type(3) {
    display: none;
}

@media (min-width: 576px) {
    .article-square__wrapper .article-square__item:nth-of-type(3) {
        display: block;
    }

    .article-square__wrapper .article-square__item {
        width: calc((100% - 32px) / 3);
    }
}

@media (min-width: 992px) {
    .article-square__wrapper .article-square__item {
        width: calc((100% - 40px) / 3);
    }
}