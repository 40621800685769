.editors-pick__wrapper .editors-pick__item {
    width: calc((100% - 16px) / 2);
}

@media (min-width: 576px) {
  .editors-pick__wrapper .editors-pick__item {
    width: calc((100% - 32px) / 3);
  }
}

@media (min-width: 992px) {
    .editors-pick__wrapper .editors-pick__item {
        width: calc((100% - 40px) / 3);
    }
}