.post-info__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.post-info__category-link:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.post-info__publish-date:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  left: -13px;
  bottom: 7px;
  background: #b8c3cf;
}
