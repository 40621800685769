footer {
  color: var(--dark-grey-text-color);
  max-width: 980px;
  margin: auto;
  padding-top: 48px;
  border-top: 1px solid #c4c4c4;
}

.footer-container {
  max-width: 100%;
  margin: auto;
  padding: 0 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
}

.footer-logo {
  display: flex;
  margin: auto;
  margin-bottom: 24px;
}

.footer-column__logo {
  width: 100%;
  margin-bottom: 24px;
}

.footer-column__socials {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
}

.footer-column__socials-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-column__socials-text p:first-child {
  margin-bottom: 8px;
}

.footer-column__link-wrapper {
  line-height: 16px;
}

.footer-column__link {
  transition: var(--transiton);
}

.footer-column__link:link,
.footer-column__link:visited,
.footer-column__link:active {
  color: var(--dark-grey-text-color);
}

.footer-column__link:hover {
  opacity: 0.6;
}

.footer-column__socials .footer-column__title {
  display: flex;
}

.footer-column__nav {
  width: 100%;
  text-align: center;
}

.footer-column__title {
  margin-bottom: 8px;
}

.footer-copyright__mob {
  display: flex;
  max-width: 190px;
  margin: auto;
  text-align: center;
  color: var(--dark-grey-text-color);
  margin-top: 28px;
  margin-bottom: 32px;
}

.footer-copyright__desk {
  display: none;
  margin: auto;
  text-align: center;
  padding: 0 0 16px;
}

.footer-column__logo {
  text-align: center;
}

.footer-column__redaksi{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
}
@media (min-width: 576px) {
}

@media screen and (min-width: 768px) {
  .footer-column__logo {
    text-align: left;
  }

  .footer-column__logo {
    max-width: 240px;
  }

  .footer-column__socials {
    display: none;
  }
  .footer-column__redaksi .footer-column__title {
    display: block;
  }

  .footer-copyright__desk {
    display: block;
    margin-top: 24px;
    max-width: 768px;
  }

  .footer-copyright__mob {
    display: none;
    margin-bottom: 0;
  }

  .footer-column__socials {
    order: 120;
    align-items: flex-start;
  }
  .footer-column__redaksi {
    order: 120;
    align-items: flex-start;
  }

  .footer-column {
    width: calc((100% - 40px) / 4);
  }

  .footer-column__socials-text {
    align-items: flex-start;
  }
  .footer-column__redaksi-text {
    align-items: flex-start;
  }

  .footer-container {
    justify-content: space-between;
  }

  .footer-logo {
    margin: initial;
    margin-bottom: 24px;
  }

  .footer-column__nav {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .footer-column__logo,
  .footer-column__nav {
    margin-right: 60px;
    align-items: flex-start;
  }

  .footer-column {
    width: initial;
  }

  .footer-container {
    justify-content: flex-start;
  }
}
