.breadcrumb-dropdown__outer {
  padding-top: 0;
  position: absolute;
  z-index: 111;
  top: 42px;
  animation: growDown 400ms ease-in-out;
  transform-origin: top;
  user-select: none;
  left: 50%;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.breadcrumb-dropdown__wrapper {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding: 8px;
  background: #fff;
  border: 1px solid var(--line-color);
  border-radius: 8px;
  min-width: 110px;
  position: relative;
  left: -50%;
}

.breadcrumb-dropdown__wrapper:after {
  position: absolute;
  top: -9px;
  right: 0;
  left: 0;
  margin: auto;
  content: "";
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  border-left: 1px solid var(--line-color);
  border-top: 1px solid var(--line-color);
  z-index: 111;
  background: #fff;
  transition: var(--transition);
}

.breadcrumb-dropdown__wrapper li {
  margin-left: 0;
}

.breadcrumb-dropdown__wrapper li:before {
  display: none;
}

a.breadcrumb-dropdown__link {
  padding: 4px 0;
  color: var(--neutral-color);
  transition: var(--transition);
  display: block;
  border-bottom: 1px solid var(--line-color);
}

.breadcrumb-dropdown__item:last-child .breadcrumb-dropdown__link {
  border-bottom: none;
}

.breadcrumb-dropdown__link:hover {
  color: var(--text-color);
}
