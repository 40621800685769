/* styling article ol, ul, etc */
.article-main-content__wrapper ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .article-main-content__wrapper ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .article-main-content__wrapper div>ul {
    list-style: none;
    padding-left: 0;
  }
  
  .article-main-content__wrapper div>ul li .li-wrapper {
    position: relative;
    padding-left: 40px;
  }

  .article-main-content__wrapper div>ul li .li-wrapper:before {
    width: 4px;
    height: 4px;
    content: "";
    background-color: #2d2d2d;
    position: absolute;
    border-radius: 50%;
    left: 18px;
    top: 13px;
  }

  .article-main-content__wrapper .addon.daftar-isi, .daftar-isi {
    padding: 10px;
  }

  .article-main-content__wrapper .daftar-isi{
    max-width: 530px;
    background-color: #d2d9e2;
  }

  .article-main-content__wrapper .addon:after, .addon:before {
    content: " ";
    display: table;
  }

  .article-main-content__wrapper .addon {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .article-main-content__wrapper .daftar-isi>.title {
    font-weight: 700;
  }

  .article-main-content__wrapper .daftar-isi-content {
    background-color: #fff;
    border-radius: 5px;
  }

  .article-main-content__wrapper .daftar-isi-content ul{
    margin: 0;
    padding: 0;
    display: block;
  }

  .article-main-content__wrapper .daftar-isi-content li.level-1{
    border-bottom: 1px dashed #d2d9e2;
    font-weight: 700;
    /* padding-left: 7px; */
    padding-right: 7px;
  }

  /* .article-main-content__wrapper .daftar-isi-content li.level-1:after, .daftar-isi-content li.level-2:after {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #666;
    left: 10px;
    top: 12px;
    content: "";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  } */

  .article-main-content__wrapper .daftar-isi-content li, .daftar-isi ul{
    position: relative;
    margin: 0;
  }
  .article-main-content__wrapper table::-webkit-scrollbar {
    height: 10px;
  }
  .article-main-content__wrapper table::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  .article-main-content__wrapper table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ce1818;
  }
  .article-main-content__wrapper table {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    /* border: #AEB6BF solid 1px; */
    font-weight: 600;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .article-main-content__wrapper thead {
    height: 65px;
    border: none;
    border-collapse: collapse;
    border-radius: 4px 4px 0 0;
    width: 100%;
    font-size: 16px;
    color: #000;
  }

  .article-main-content__wrapper tr th:nth-child(2) 
  {
    width: 500px;
  }
  .article-main-content__wrapper tr th {
    padding: 15px 20px;
    background-color: #FFD8D3;
    vertical-align: middle;
    border: 1px solid #AEB6BF;
    border-bottom-width: 2px;
  }
  .article-main-content__wrapper tr th:first-child {
    border-radius: 10px 0 0 0;
    border: 1px solid #AEB6BF;
    border-bottom-width: 2px;
  }
  .article-main-content__wrapper tr th:last-child {
    border-radius: 0 10px 0 0;
    border: 1px solid #AEB6BF;
    border-bottom-width: 2px;
  }
  .article-main-content__wrapper tr td {
    padding: 20px;
    font-size: 15px;
    line-height: 24px;
    color: #6d6d6d;
    border-left: #AEB6BF solid 1px;
    border-right: #AEB6BF solid 1px;
  }
  .article-main-content__wrapper tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
    border-bottom: #AEB6BF solid 1px;
  }
  .article-main-content__wrapper tr:last-child td {
    border-bottom: #AEB6BF solid 1px;
  }
  .article-main-content__wrapper tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
    border-bottom: #AEB6BF solid 1px;
  }
  .article-main-content__wrapper table thead {
    background-color: #FFD8D3!important;
  }
  .article-main-content__wrapper tr:nth-child(odd) {
    background: #EBEFF3!important;
  }
  .article-main-content__wrapper tr:nth-child(even) {
    background-color: #ffffff!important;
  }

  .article-main-content__wrapper .body-content li {
    line-height: 30px;
  }

.article-main-content__wrapper .addon-affiliate {
  background-image: url('https://i.ibb.co/Hr13Djz/bg-banner.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 75px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 20px;
  padding: 0 20px;
  margin-bottom: 10px;
}

.article-main-content__wrapper .addon-affiliate a{
  color:#fff;
  text-decoration: none;
}
/* .article-main-content__wrapper .addon-affiliate .image-container {
  display: flex;
  justify-content: center;
} */

.article-main-content__wrapper .addon-affiliate .image-container .article-images {
  width: 60px!important;
  height: 60px!important;
  margin-bottom: 0!important;
  border-radius: 10px;
}

.article-main-content__wrapper .addon-affiliate .image-container img{
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid #ce1818;
  vertical-align: middle;
}

.article-main-content__wrapper .addon-affiliate .title-container{
  width: 425px;
}

.article-main-content__wrapper .addon-affiliate .title-container .title{
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: -10px;
}

.article-main-content__wrapper .addon-affiliate .title-container .description{
  color: #000;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.article-main-content__wrapper .button-aff{
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  background: linear-gradient(93.14deg, #CE1818 32.01%, #FFE660 149.92%);
  font-weight: 600;
  width: 150px;
  text-align: center;
  font-size: 16px;
}

.article-main-content__wrapper .button-aff a{
  color: #fff!important;
}

.article-main-content__wrapper .button {
    position: relative;
    width: 150px;
    height: 40px;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
    font-family: Open Sans;
    line-height: 38px;
    text-align: center;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.article-main-content__wrapper .addon {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
}

.article-main-content__wrapper .addon.addon-apps, .addon.addon-article, .addon.addon-gadgets, .addon.addon-movie, .addon.addon-theater {
    border: 1px solid #dadada;
}

.article-main-content__wrapper .addon.addon-article {
    height: 102px!important;
    padding: 20px!important;
}

.article-main-content__wrapper .addon-article .addon-image-container {
    position: absolute;
    height: 60px;
    width: 60px;
    text-align: center;
    overflow: hidden;
}

.article-main-content__wrapper .article-container .body-content .article-images {
    max-width: 100%!important;
    height: auto!important;
    margin: auto;
    margin-bottom: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABWQAAAJYAQMAAADfXH2qAAAABlBMVEXu7u7Nzc0qm56VAAAE5klEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABm54xtJgehIAxyQEgJlOJWrhPo4Er4W3Ep7uAcbrDynG0BBtK7YEbiC1Ybjt7OY7yGx2QymUwmk8lkMplMJpPJZPJv/DJC2I8RYvkaITyMECs2owNwGBksINRmDjiNDAFAMipEALtRARBqswUXMvngcSGTDysuZPIBEDKuxYVMPjhcyORDwIVMPkRcyOQDINRmCy5k8sHjQiYfVlzI5EMEdNrM4kImHxxufkTyITw6nUg+rI8HrEib5f6KEvmwZAsEiXzwub2cRD6EvHRZiTaLJRaiQD68JQ0C+eBqKjiBfPC1olagzdbXrZE/H5qVINDnw9KY1dHng2sWAkvfZqFdZCN7PnQCA3k+9D++I8+HpdNnydvM97995M6Hte+rwJ0Pw5rlqPPBjm1F3WZuXLIicz74MQ48c5v5sZSOXW1vU3a1fdKu5GpNR+BWew5RzK12yALLrXYfs5harRnwzGq/ZmBhVnuYAcusdjcjK7HaZEYCsdrdjERitR8p355mwDGrRRptS612NG6kVvuRek4YjOu41SIpPd8Oxo3kajvjWvb/ZVr/eTvjBnq1uzEaLxk9XuPyb5l5tMZl30f36I0bBNS+xo0CaqtxLfeJO49+T0dBbTVukFBb9yIl1JZ9XvKToh6tcR292nU8n4A/xGp/Yzj7gd/Eah2GczUn74Skh32Nm4Uzq02xPw+GnVlt0Vhtm5jf2qVqXIsH4slej9JbxbZfbrUG7flbHNRqi8o923ajfttcn3GzbZkn0j3KSewz25ZbbSrG/cHNwa02b5sXdu5dkufjJXHXNt3GrZzU9z54DFNF/GrbiS3y3b306Cskgdq6alvu+0o8unG4D7va1Br3YN+V7malN/badiOy5PfsZCfkfPjSn0/o7k+gr213NwW92tQYl/+kSnunCvttVh7tCWx+tam5C0jFCY9xE39tmzus6G9h80hV7Vfg9Fr+smjU1ry1FVCbcm2lnCDSZcW3Uk6Ya8J/xyNJrQlNbQWcoFpbBbWlthpOUK2tglqtNWGTeqrZXyfoTGfctdWZzrh9KzCxhdcJAtNw2KoTFCYNj1pbnVmS27c6syS3E3RmSaoTPLnardRWYg79KL7VmXy5nUA/+fK3vTu4iRgGogCaVQ45poSUklboZCPRGL3QAAWgDXFiWzlQwIz2PQnOlpnRB7HyLzv1uk/CFPkljXE/bLe7nUM/r7Mfvm5zu0Z+Aaadrk9C7Nd1zp98nYToS1a3qt5t9CWrl7n10y6RnwJq/3rqk7DGfs3svM2ffrfRX4qrazZe32Mv2XXAV5uEKXQ2FPthq3e7hM6GoS1WPe0aOhv6mp2TEH/J6qxevyeMsbOh58N1t1PwbGj5sJ1zuwTPhr5m52mf4Zes/m1WJiH0u2v3fPgdy1f4bGj5UE47h8+Gtmafx2kzLNmVD9/HaZ/hs+HeVBQ/G/I1fQx7phaVZA01udp/cjUrPTItWbZGsFxta7ma7MZMY5usgTFZu2Wu5tD4rRl3j0xLlq1NOFdT85wmG4ox0ZJla29P1owf99OL/5kSLdmRD3myofgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgLfwB371bVaqc5ojAAAAAElFTkSuQmCC);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.article-main-content__wrapper .addon-article .addon-details-container {
    position: relative;
    min-height: 55px;
    height: auto;
    padding-left: 75px;
    padding-right: 126px;
}

.article-main-content__wrapper .addon-apps .addon-details-container .button, .addon-article .addon-details-container .button {
    position: absolute;
    width: 120px;
    height: 40px;
    right: 0;
    top: 0;
    font-size: 11.2px;
    line-height: 38px;
}

.article-main-content__wrapper .button-blue {
    background-color: #008efa;
    border: 1px solid #1f71b0;
}

.article-main-content__wrapper .button-blue:hover {
    background-color: #1c7ec7;
}

.article-main-content__wrapper .button:after {
    position: absolute;
    height: 1px;
    width: calc(100% - 4px);
    top: 0;
    left: 2px;
    content: "";
    background-color: hsla(0,0%,100%,.3);
}

.article-main-content__wrapper .link-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    cursor: pointer;
}

.article-main-content__wrapper .addon-article .addon-details-container .subtitle, .addon-article .addon-details-container .title, .addon-theater .addon-details-container .subtitle, .addon-theater .addon-details-container .title{
    -webkit-line-clamp: 1;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 100%;
    padding: 0!important;
}

.article-main-content__wrapper .color.brightred {
    color: var(--primary-red-color);
}

.article-main-content__wrapper .addon-article .addon-details-container .title-container .title, .addon-theater .addon-details-container .title-container .title {
    position: relative;
    width: auto;
    height: 26px;
    font-weight: 700;
    line-height: 26px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: normal;
}

.article-main-content__wrapper .addon-apps .addon-details-container .category-container .app-category.os-category:hover, .addon-article .addon-details-container .title-container .title:hover, .addon-movie .addon-details-container .title-container .title:hover, .addon-theater .addon-details-container .title-container .title:hover {
    text-decoration: underline;
}

.article-main-content__wrapper .video-container {
  overflow: hidden;
  position: relative;
  height: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 56.25%;
}

.article-main-content__wrapper .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.article-main-content__wrapper .addon-apps .addon-image-container, .addon-gadgets .addon-image-container {
  position: absolute;
  width: 55px;
  height: 55px;
}

.article-main-content__wrapper .addon-apps .addon-details-container {
  position: relative;
  height: 55px;
  padding-left: 75px;
  /* padding-right: 170px; */
}

.article-main-content__wrapper .addon-apps .addon-details-container .button, .addon-article .addon-details-container .button {
  position: absolute;
  width: 120px;
  height: 40px;
  right: 0;
  top: 0;
  font-size: 11.2px;
  line-height: 38px;
}

.addon-apps .addon-details-container .title-container {
  font-size: 16px;
  line-height: 32px;
  color: #989898;
}

.addon-apps .addon-details-container .category-container, .addon-apps .addon-details-container .title-container {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.addon-apps .addon-details-container .title-container .title {
  position: relative;
  font-weight: 700;
  color: #4a4a4a;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.addon-apps .addon-details-container .category-container:after, .addon-apps .addon-details-container .title-container:after, .addon-article .addon-details-container .title-container .subtitle:after, .addon-article .addon-details-container .title-container .title:after, .addon-movie .addon-details-container .title-container .subtitle:after, .addon-movie .addon-details-container .title-container .title:after, .addon-theater .addon-details-container .title-container .subtitle:after, .addon-theater .addon-details-container .title-container .title:after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  content: "";
  background: -webkit-linear-gradient(right,#fff 30%,hsla(0,0%,100%,0) 80%);
  background: -o-linear-gradient(left,#fff 30%,hsla(0,0%,100%,0) 80%);
  background: -webkit-gradient(linear,right top,left top,color-stop(30%,#fff),color-stop(80%,hsla(0,0%,100%,0)));
  background: -o-linear-gradient(right,#fff 30%,hsla(0,0%,100%,0) 80%);
  background: linear-gradient(
270deg
,#fff 30%,hsla(0,0%,100%,0) 80%);
}

.addon-apps .addon-details-container .category-container .app-category {
  position: relative;
  display: inline-block;
  height: 20px;
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #a9a9a9;
  vertical-align: top;
}

.category-container .app-category:not(.os-category):before {
  position: absolute;
  width: 4px;
  height: 4px;
  left: 2px;
  top: 8px;
  content: "";
  background-color: #a9a9a9;
  border-radius: 50%;
}

.article-main-content__wrapper .addon.addon-apps.addon-multi .container{
  margin: 16px 0;
  padding: 0;
}

.article-main-content__wrapper .addon-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 24px;
}