.article-square__title {
    background: url("@assets/images/submenu-icon.svg") no-repeat;
    padding-left: 32px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  
  .article-square__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .article-square__item {
    background: var(--frame-color);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .article-square__container {
    margin-bottom: 32px;
  }
  
  .article-square__wrapper .article-square__item {
    margin-bottom: 16px;
  }
  
  .article-square__image-wrapper {
    width: 100%;
    display: grid;
  }
  
  .article-square__image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .article-square__info-wrapper {
    padding: 12px;
  }
  
  .article-square__info-title {
    color: var(--dark-grey-text-color);
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
    overflow: hidden;
  }
  
  .article-square__info-title-link {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  .article-square__info-category {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  
  .article-square__info-category-link {
    color: var(--primary-red-color);
    text-transform: capitalize;
    /* transition: var(--transiton); */
  }
  .article-square__info-category-link:hover{
    text-decoration: underline;
  }
  
  .article-square__info-title-link {
    color: var(--text-color);
  }
  
  /* .article-square__info-title-link:hover {
    opacity: .7;
  } */
  
  @media (min-width: 576px) {
    .article-square__title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
  
    .article-square__info-title-link {
      color: var(--frame-color);
      transition: var(--transiton);
    }
  
    .article-square__item {
      min-height: 150px;
      border-radius: 8px;
      color: white;
      position: relative;
      /* width: calc((100% - 16px) / 3); */
    }
  
    .article-square__image-wrapper {
      height: 100%;
    }
  
    .article-square__image-wrapper::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(47, 47, 47, 0) 0%,
        rgba(47, 47, 47, 0.51) 51.04%,
        var(--dark-grey-text-color) 77.6%
      );
    }
  
    .article-square__item:hover .article-square__image-wrapper::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(206, 24, 24, 0) 0%,
        rgba(206, 24, 24, 0.24) 51.04%,
        var(--primary-red-color) 80.73%
      );
    }
  
    .article-square__item:hover .article-square__info-category-link {
      color: #ffea60;
    }
  
    .article-square__info-wrapper {
      padding: 0 16px;
      position: absolute;
      bottom: 8px;
    }
  
    .article-square__info-title {
      margin-bottom: 8px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  
    .article-square__info-category {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 16px;
      line-height: 24px;
    }
  }
  
  @media (min-width: 768px) {
    .article-square__item {
      margin-bottom: 16px;
      height: 250px;
    }
  }
  
  @media (min-width: 992px) {
    .article-square__title {
      font-weight: 700;
      font-size: 24px;
    }
  
    .article-square__wrapper {
      max-width: 880px;
    }
  
    /* .article-square__item {
      width: calc((100% - 40px) / 3);
    } */
  
    .article-square__info-wrapper {
      bottom: 20px;
    }
  
    .article-square__info-title {
      font-size: 20px;
      line-height: 24px;
    }
  
    .article-square__info-category {
      font-size: 16px;
      line-height: 24px;
    }
  }
  