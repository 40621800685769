.one-tag {
  padding: 8px 16px;
  background: var(--light-blue);
  border-radius: 24px;
  margin-right: 8px;
  color: #000;
  transition: var(--transiton);
  margin-bottom: 12px;
}

.one-tag:hover {
  opacity: 0.8;
}
