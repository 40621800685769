.rating__wrapper{
    display: inline;
    margin-left: 12px;
}

.fa-star{
    margin-right: 4px;
}

.fa-star.checked {
    color: orange;
}