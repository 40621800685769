.lazy-loading-block {
  width: 100%;
  height: 200px;
  margin-bottom: 24px;
  background: #ddd;
}

.video-section__item-outer_hidden {
  width: 0;
  height: 0;
  opacity: 0;
}

.video-section__item-img {
  width: 100%;
}

.video-section__item {
  width: calc((100% - 20px) / 2);
  margin-bottom: 24px;
  position: relative;
}

.video-section__item-wrapper {
  position: relative;
  margin-bottom: 8px;
}

.video-section__item-title-wrapper {
  margin-bottom: 8px;
  display: block;
}

.video-section__item-img-link {
  display: flex;
  position: relative;
}

.video-section__item-wrapper:after {
  position: absolute;
  width: 30px;
  height: 20px;
  border-radius: 50%;
  left: calc(50% - 10px);
  top: calc(50% - 4px);
  transform: translate(-50%, -50%);
  margin: auto;
  cursor: pointer;
  transition: var(--transiton);

  content: "\ea9d";
  font-family: 'icomoon' !important;
  font-size: 48px;
  -webkit-font-smoothing: antialiased;
  color: #CE1818;
  opacity: 0.9;
  background: #fff;
}

.video-section__item-wrapper:hover:after {
  opacity: 0.6;
}

.video-section__item-post-date {
  color: var(--neutral-color);
}

.video-section__item-title {
  color: var(--text-color);
  transition: var(--transition);
}

.video-section__item-title:hover {
  opacity: 0.6;
}

.video-section__item-number-wrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  background: var(--primary-red-color);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-section__item-number {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  padding-left: 19px;
  background: url(../../assets/images/two-arrows-right-white.svg) no-repeat left
    center;
}

@media (min-width: 768px) {
  .video-section__item-wrapper:after {
    content: "\ea9d";
    font-family: 'icomoon' !important;
    font-size: 48px;
    -webkit-font-smoothing: antialiased;
    color: #212936;
    opacity: 0.9;
  }
  .video-section__item {
    margin-bottom: 16px;
  }
}
