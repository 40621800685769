.post-block-large__img {
  width: 100%;
  border-radius: 4px;
}

.post-block-large__title {
  margin-bottom: 8px;
}

.post-block-large__link {
  color: var(--text-color);
  transition: var(--transiton);
}

.post-block-large__link:hover {
  opacity: 0.6;
}

@media (min-width: 576px) {
  .post-block-large__img {
    margin-bottom: 16px;
    max-width: 500px;
    width: initial;
  }
}

@media (min-width: 768px) {
  .post-block-large__title {
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
  }
}

@media (min-width: 992px) {
  .post-block-large__img {
    max-width: 100%;
  }
}
