.posts-container__outer {
  padding: 0 16px;
}

.recent-posts__bottom {
  /* margin-bottom: 40px; */
  padding: 0 16px;
}


@media (min-width:768px){
  .posts-container__outer{
    padding: 0;
  }
  
  .recent-posts__bottom {
    padding: 0;
  }

  .recent-posts__bottom .post-block-with-category__img{
    width: 180px;
    min-height: 90px;
  }
  
  .recent-posts__bottom .post-block-with-category__text{
    font-size: 20px;
  }

}