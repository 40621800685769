.popular-today__container {
  display: none;
}

.popular-today__item:last-child {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .popular-today__container {
    width: 280px;
    font-weight: 700;
    display: block;
    margin: auto;
    margin-bottom: 80px;
  }

  .popular-today__title {
    padding: 0 10px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 48px;
    margin-left: -8px;
  }
}
/* .popular-today__container{
    display: none;
}

@media (min-width: 576px){
    .popular-today__container{
        width: 280px;
        font-weight: 700;
        display: block;
        margin:auto;
    }
    
    .popular-today__title{
        padding:16px;
        text-transform: uppercase;
        font-size: 1.5rem;
    }
    
    .popular-today__item{
        padding: 16px 0 16px 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid var(--line-color);
    }
    
    .popular-today__item-num{
        font-size: 2.5rem;
        line-height: 1;
        font-family: "Butler";
    }
    
    .popular-today__item-ttl{
        width: 216px;
        color: var(--text-color);
        transition: var(--transiton);
    }

    .popular-today__item-ttl:hover{
        opacity: 0.7;
    }

} */
