.article-main-content__wrapper .lazyload, .lazyloading {
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter .3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}

.article-main-content__wrapper {
  padding-bottom: 32px;
  position: relative;
}

.article-main-content__wrapper .share-desk__container {
  position: absolute;
  left: 0;
}

.article-main-content__inner h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #000;
  margin-bottom: 15px;
  padding-right: 10px;
}

.article-main-content__inner {
  font-size: 17px;
  line-height: 26px;
  color: #000;
}

.related-article.news-content:nth-child(n + 2) {
  display: none;
}

.article-main-content__wrapper .related-article .news-item {
  width: calc((100% - 16px) / 2);
  margin-bottom: 24px;
  position: relative;
  margin-right: 16px;
  background: var(--frame-color);
  border-radius: 8px;
}

.article-main-content__wrapper .related-article .news-item:nth-child(2n) {
  margin-right: 0;
}

.article-main-content__wrapper .related-article .news-item a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1111;
}

.article-main-content__wrapper .related-article .news-item:nth-child(n + 7) {
  display: none;
}

.article-main-content__wrapper .related-article .news-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.article-main-content__wrapper .related-article .title {
  line-height: normal;
}

.article-main-content__wrapper h3 {
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 30px;
  font-weight: bold;
}

.article-main-content__inner p {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 31px;
}

.article-main-content__inner .image-credits {
  display: block;
  margin-top: 7px;
  font-size: 12px;
  line-height: 24px;
  color: #b2b2b2;
  text-align: center;
}

.article-main-content__inner .article-images {
  margin: auto;
  margin-bottom: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABWQAAAJYAQMAAADfXH2qAAAABlBMVEXu7u7Nzc0qm56VAAAE5klEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABm54xtJgehIAxyQEgJlOJWrhPo4Er4W3Ep7uAcbrDynG0BBtK7YEbiC1Ybjt7OY7yGx2QymUwmk8lkMplMJpPJZPJv/DJC2I8RYvkaITyMECs2owNwGBksINRmDjiNDAFAMipEALtRARBqswUXMvngcSGTDysuZPIBEDKuxYVMPjhcyORDwIVMPkRcyOQDINRmCy5k8sHjQiYfVlzI5EMEdNrM4kImHxxufkTyITw6nUg+rI8HrEib5f6KEvmwZAsEiXzwub2cRD6EvHRZiTaLJRaiQD68JQ0C+eBqKjiBfPC1olagzdbXrZE/H5qVINDnw9KY1dHng2sWAkvfZqFdZCN7PnQCA3k+9D++I8+HpdNnydvM97995M6Hte+rwJ0Pw5rlqPPBjm1F3WZuXLIicz74MQ48c5v5sZSOXW1vU3a1fdKu5GpNR+BWew5RzK12yALLrXYfs5harRnwzGq/ZmBhVnuYAcusdjcjK7HaZEYCsdrdjERitR8p355mwDGrRRptS612NG6kVvuRek4YjOu41SIpPd8Oxo3kajvjWvb/ZVr/eTvjBnq1uzEaLxk9XuPyb5l5tMZl30f36I0bBNS+xo0CaqtxLfeJO49+T0dBbTVukFBb9yIl1JZ9XvKToh6tcR292nU8n4A/xGp/Yzj7gd/Eah2GczUn74Skh32Nm4Uzq02xPw+GnVlt0Vhtm5jf2qVqXIsH4slej9JbxbZfbrUG7flbHNRqi8o923ajfttcn3GzbZkn0j3KSewz25ZbbSrG/cHNwa02b5sXdu5dkufjJXHXNt3GrZzU9z54DFNF/GrbiS3y3b306Cskgdq6alvu+0o8unG4D7va1Br3YN+V7malN/badiOy5PfsZCfkfPjSn0/o7k+gr213NwW92tQYl/+kSnunCvttVh7tCWx+tam5C0jFCY9xE39tmzus6G9h80hV7Vfg9Fr+smjU1ry1FVCbcm2lnCDSZcW3Uk6Ya8J/xyNJrQlNbQWcoFpbBbWlthpOUK2tglqtNWGTeqrZXyfoTGfctdWZzrh9KzCxhdcJAtNw2KoTFCYNj1pbnVmS27c6syS3E3RmSaoTPLnardRWYg79KL7VmXy5nUA/+fK3vTu4iRgGogCaVQ45poSUklboZCPRGL3QAAWgDXFiWzlQwIz2PQnOlpnRB7HyLzv1uk/CFPkljXE/bLe7nUM/r7Mfvm5zu0Z+Aaadrk9C7Nd1zp98nYToS1a3qt5t9CWrl7n10y6RnwJq/3rqk7DGfs3svM2ffrfRX4qrazZe32Mv2XXAV5uEKXQ2FPthq3e7hM6GoS1WPe0aOhv6mp2TEH/J6qxevyeMsbOh58N1t1PwbGj5sJ1zuwTPhr5m52mf4Zes/m1WJiH0u2v3fPgdy1f4bGj5UE47h8+Gtmafx2kzLNmVD9/HaZ/hs+HeVBQ/G/I1fQx7phaVZA01udp/cjUrPTItWbZGsFxta7ma7MZMY5usgTFZu2Wu5tD4rRl3j0xLlq1NOFdT85wmG4ox0ZJla29P1owf99OL/5kSLdmRD3myofgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgLfwB371bVaqc5ojAAAAAElFTkSuQmCC);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.article-main-content__inner .article-images img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.article-main-content__inner a {
  color: var(--primary-red-color);
}

.article-main-content__inner a:hover {
  text-decoration: underline;
}

.article-main-content__wrapper .article-images img {
  border-radius: 8px;
  word-break: keep-all;
}

.article-main-content__wrapper .related-article .clear {
  display: none;
}

.article-main-content__wrapper .related-article .news-title {
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  margin-bottom: 24px;
  margin-top: 24px;
  position: relative;
  display: flex;
  text-transform: uppercase;
  align-items: center;
}

.article-main-content__wrapper .related-article .news-title > span {
  padding-left: 11px;
}

.article-main-content__wrapper .related-article .news-title::before {
  content: "\e908";
  color: #ce1818;
  font-family: 'icomoon';
  font-size: initial;
  font-weight: initial;
}

.article-main-content__wrapper .article-images {
  margin-bottom: 24px;
  max-width: 100%;
  height: auto !important;
}

.article-main-content__wrapper .related-article .article-images,
.article-main-content__wrapper .related-article .article-images img {
  width: 100% !important;
  height: 146px !important;
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: cover;
}

.article-main-content__wrapper .related-article .news-detail {
  position: static;
  bottom: 0;
  margin: 16px;
  color: var(--dark-grey-text-color);
  font-size: 13px;
  line-height: normal;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.related-article.news-content {
  border-top: 1px solid var(--line-color);
}

@media (min-width: 768px) {
  .article-main-content__wrapper .related-article .news-item:nth-child(2n) {
    margin-right: 20px;
  }

  .article-main-content__wrapper .related-article .news-item:nth-child(3n) {
    margin-right: 0;
  }

  .article-main-content__wrapper {
    padding-top: 24px;
  }

  .article-main-content__wrapper .related-article .news-item {
    width: calc((100% - 42px) / 3);
  }

  .article-main-content__wrapper .related-article .news-item {
    height: 250px !important;
    background: none;
    border-radius: 0;
  }

  .article-main-content__wrapper .related-article .article-images,
  .article-main-content__wrapper .related-article .article-images img {
    height: 250px !important;
  }

  .article-main-content__wrapper .related-article .news-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(47, 47, 47, 0) 0%,
      rgba(47, 47, 47, 0.51) 51.04%,
      var(--dark-grey-text-color) 77.6%
    );
  }

  .article-main-content__wrapper .related-article .news-item:hover::before {
    background: linear-gradient(
      180deg,
      rgba(206, 24, 24, 0) 0%,
      rgba(206, 24, 24, 0.24) 51.04%,
      #ce1818 80.73%
    );
  }
  .article-main-content__wrapper .related-article .news-detail {
    position: absolute;
    color: #fff;
    line-height: 24px;
    font-weight: 700;
    font-size: 20px;
  }

  /* .article-main-content__wrapper .related-article .news-detail {
    font-size: 15px;
  } */
}
