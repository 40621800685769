.apps-top-info__wrapper{
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
}

.apps-info__wrapper{
    margin-bottom: 12px;
    /* width: 250px; */
    display: flex;
    align-items: center;
}

.apps-icon__wrapper{
    margin-right: 16px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 8px;
}

.apps-payment-type{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #636C77;
}

.apps-name__title{
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #212936;
    margin-bottom: 4px;
}

.apps-name__editor-rating{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #AEB6BF;
}

.apps-flex-item.summary{
    width: 100%;
}

.apps-summary__wrapper{
    margin-bottom: 16px;
}

.apps-summary{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
}

.apps-summary-detail{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #636C77;
}

.apps-summary-detail h2 {
    font-weight: 700;
    font-size: 22px;
    color: #636C77;
    margin: 15px 0 10px 0;
    line-height: 35px;
}

.apps-summary-detail p {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 31px;
}

.apps-summary-detail ul {
    margin: 0;
    padding-left: 1rem;
}

.apps-summary-detail ul li {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    text-indent: -0.9em;
    margin-left: 0.5em;
}

.apps-summary-detail ul li::before {
    content: "•";
    padding-right: 8px;
    color: #636C77;
}

.apps-spec__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.apps-spec__item{
    width: calc(100% / 2);
    margin-bottom: 24px;
}

.apps-spec__type{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #AEB6BF;
}

.apps-spec__name{
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #212936;
}

.apps-actions__wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.btn-download-apps-red{
    display: block;
    background: linear-gradient(93.14deg, #CE1818 32.01%, #FFAC60 149.92%);
    border-radius: 8px;
    width: 164.9px;
    height: 40px;
    color: #fff;
    padding: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}
.btn-download-apps-red:hover{
    background: var(--primary-red-color);
}

.btn-grey{
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #212936;
    background: #EBEFF3;
    border-radius: 8px;
    width: 164.9px;
    height: 40px;
    padding: 8px;
}

.btn-grey:hover{
    background: var(--line-color);
}

@media(min-width: 500px){
    .apps-spec__item{
        width: calc(100% / 3);
    }
    .btn-download-apps-red, .btn-grey{
        width: 200px;
    }
    .apps-actions__wrapper{
        justify-content: space-evenly;
    }
}

@media(min-width:768px){
    .apps-top-info__wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .apps-flex-item.info{
        order: 1;
    }
    
    .apps-flex-item.action{
        order: 2;
    }
    
    .apps-flex-item.summary{
        order: 3;
        margin-top: 32px;
    }

    .apps-spec__item{
        width: calc(100% / 6);
    }

    .apps-name__title{
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
    }

    .apps-icon__wrapper{
        width: 64px;
        height: 64px;
    }

    .apps-payment-type{
        line-height: 22px;
    }

    .apps-summary{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }

    .apps-summary-detail{
        font-size: 16px;
        line-height: 26px;
    }

    .btn-download-apps-red, .btn-grey{
        width: 200px;
    }
    
    .btn-download-apps-red{
        margin-right: 16px;
    }


}