.eula-section__container {
    display: flex;
    width: 100%;
}
.static-sidebar {
    flex-shrink: 0;
    flex-basis: 30%;
}
.static-content {
    padding: 10px;
    border-radius: 12px;
    border: 1px solid rgb(116, 113, 113);
    margin: 1rem 0 1rem 2rem;
}
.static-content .title {
    font-size: 16px;
}
.static-content .title span {
    padding: 10px 0 0 0;
    font-weight: bold;
}
.static-content .title,
.static-content .content {
    padding: 25px;
}

.content h3 {
    font-size: 16px;
    font-weight: bold;
}
.content p {
    line-height: 1.5rem;
    font-size: 14px;
    margin-bottom: 15px;
}

@media only screen and (max-width: 576px) {
    .eula-section__container {
        flex-direction: column;
    }
    .static-content {
        padding: 0;
        border: none;
        margin: 0;
    }
    .static-content .title,
    .static-content .content {
        padding: 0;
        margin-top: 15px;
    }
    .static-content .title h1 {
        font-size: 16px;
    }
    .static-content .title span,
    .static-content .content h3 {
        font-size: 14px;
    }
}