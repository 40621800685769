.sub-title__text {
  text-transform: uppercase;
  color: var(--text-color);
  margin-left: 8px;
  font-size: 16px;
}

.sub-title__text.lowercase {
  text-transform: none;
}

.sub-title__text.whiteText {
  color: var(--frame-color);
}

.sub-title__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.sub-title__image {
  width: 13px;
}

@media (min-width: 768px) {
  .sub-title__text {
    margin-left: 11px;
    font-size: 24px;
  }

  .sub-title__image {
    width: initial;
  }
}
