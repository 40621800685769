.apps-headline__wrapper {
    height: 172px;
    margin-bottom: 20px;
}

.apps-section__title .sub-title__text{
    text-transform: capitalize;
}

.react-multi-carousel-list {
    height: 100%;
}

.apps-headline__item {
    margin: 0 4px;
    position: relative;
}

.apps-image__wrapper {
    border-radius: 8px;
    overflow: hidden;
    min-height: 80px;
}
.apps-image__wrapper img{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    object-fit: cover;
    height: 160px;
}

.apps-headline__info{
    position: absolute;
    bottom: 4px;
    left: 4px;
}

.apps-headline__category {
    color: #FFFFFF;
    background: linear-gradient(93.14deg, #CE1818 32.01%, #FFAC60 149.92%);
    display: inline;
    padding: 2px 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
}

.apps-headline__title {
    /* margin-top: 4px; */
    font-weight: bold;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.react-multi-carousel-item--active {
    /* margin: -6px 12px 0px 12px; */
}

.apps-image__wrapper::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(33, 41, 54, 0) 0%, #212936 100%);
}

.apps-headline__item:hover .apps-image__wrapper::before {
    background: linear-gradient( 180deg, rgba(206, 24, 24, 0) 0%, rgba(206, 24, 24, 0.24) 51.04%, var(--primary-red-color) 80.73%);
}

.apps-headline__item img {
    width: 100%;
}

.image-item {
    padding: 20px 0 20px 20px;
}

@media (min-width:500px) {
    .apps-headline__title{
        margin-top: 4px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .apps-headline__category {
        padding: 4px 8px;
    }

    .apps-headline__info{
        bottom: 16px;
        left: 16px;
    }
}

@media(min-width:768px) {
    .apps-headline__wrapper {
        height: 316px;
        margin-bottom: 40px;
    }
    .apps-headline__item {
        margin: 0 24px;
    }
    .apps-headline__title {
        margin-top: 12px;
        font-size: 20px;
        line-height: 24px;
    }

    .apps-image__wrapper {
        min-height: 180px;
    }

    .apps-image__wrapper img{
        height: 268px;
    }

    .react-multi-carousel-item--active .apps-image__wrapper{
        min-height: 200px;
    }

    .react-multi-carousel-item--active {
        transform: scale(1.1);
    }
    .react-multi-carousel-item--active {
        margin: -6px 12px 0px 12px;
    }
}

/* styling dots and arrow */

.react-multi-carousel-dot button {
    display: none;
}

.react-multi-carousel-dot-list {
    bottom: 10px;
}

.react-multi-carousel-dot {
    background: #636C77;
    opacity: 0.5;
    border-radius: 4px;
    height: 4px;
    width: 24px;
    margin: 0 2px;
}

.react-multi-carousel-dot--active {
    background: #CE1818;
    opacity: 1;
}

.react-multiple-carousel__arrow {
    display: none;
}

.react-multiple-carousel__arrow--right {
    border-radius: 8px 0 0 8px;
}

.react-multiple-carousel__arrow--left {
    border-radius: 0 8px 8px 0;
}

.react-multiple-carousel__arrow--left {
    left: 0;
}

.react-multiple-carousel__arrow--right {
    right: 0;
}

@media(min-width:768px) {
    .react-multiple-carousel__arrow {
        height: 200px;
        display: block;
    }
    .react-multi-carousel-dot-list {
        bottom: 24px;
    }
}

/* styling dots and arrow ends*/