.external-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 999;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.popup, .popup-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.popup-overlay.black {
    background: rgba(0,0,0,.4);
}

.external-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 400px;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 8px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 999;
    background-color: #fff;
}
.external-popup h2 {
    margin-top: -4px;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 16px;
}
.popup-close:hover {
    color: #f24357;
    opacity: 1;
}


.popup-close {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 20px;
    font-size: 24px;
    line-height: 24px;
    opacity: .6;
    cursor: pointer;
    z-index: 6;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
}

.external-popup img {
    display: block;
    margin: auto;
}

.external-select {
    display: flex;
    margin: 0 -10px;
    margin-top: 25px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.external-popup p {
    color: #969696;
    margin: 16px 0;
}

.external-accept, .external-reject {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;  
    flex: 1 0;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 0 6px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    min-width: 130px;
}

.external-accept, .external-reject {
    color: #fff;
    background: -webkit-linear-gradient(210deg,#f7a784,#dc3a51);
    background: -o-linear-gradient(210deg,#f7a784,#dc3a51);
    background: linear-gradient(240deg,#f7a784,#dc3a51);
    opacity: .8;
}

.external-reject:hover, .external-accept:hover {
    opacity: 1;
}

.popup-overlay {
    position: absolute;
    background: #e98125;
    background: -webkit-gradient(left top,right bottom,color-stop(0,#e98125),color-stop(100%,rgba(206,10,69,.5)));
    background: -webkit-linear-gradient(-45deg,#e98125,rgba(206,10,69,.5));
    background: -o-linear-gradient(-45deg,#e98125 0,rgba(206,10,69,.5) 100%);
    background: -webkit-linear-gradient(315deg,#e98125,rgba(206,10,69,.5));
    background: -o-linear-gradient(315deg,#e98125 0,rgba(206,10,69,.5) 100%);
    background: linear-gradient(135deg,#e98125,rgba(206,10,69,.5));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e98125",endColorstr="#ce0a45",GradientType=1);
    opacity: .7;
    cursor: pointer;
}

#external {
    color: #ce0a46;
    text-decoration: underline;
}

.external-popup-title {
    margin-top: -4px;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 16px;
}