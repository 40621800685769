.apps-item{
    border: 1px solid var(--caption-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 12px;
    height: 88px;
}

.apps-item__img-wrapper{
    width: 40px;
    height: 40px;
}

.apps-item__img-wrapper img{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.apps-item__info-wrapper{
    width: 150px;
}

.apps-item__name{
    color: inherit;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.apps-item__name:hover{
    text-decoration: underline;
    cursor: pointer;
}

.apps-item__category{
    color: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color);
}
.apps-item__category:hover{
    text-decoration: underline;
    cursor: pointer;
}

.btn-download-apps{
    display: block;
    background: #EBEFF3;
    border-radius: 4px;
    padding:4px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color);
    height: 32px;
}

.btn-download-apps:hover{
    background: var(--line-color);
}


@media(min-width:588px){
    .apps-item{
        justify-content: space-between;
        width: calc((100% - 16px) / 2);
    }
}

@media(min-width:992px){
    .apps-item-nft {
        width: calc((100% - 16px) / 2) !important;
    }

    .apps-item{
        width: calc((100% - 32px) / 3);
    }
}