.apps-section__wrapper{
    margin-bottom: 16px;
}
.apps-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.apps-section__wrapper .apps-section__title .sub-title__text{
    text-transform: capitalize;
}

.apps-section__title{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-bottom:16px;
}

@media(min-width:768px){
    .apps-section__title{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}

/* styling tabs */
.apps-section__wrapper .react-tabs__tab-list{
    border-bottom: none;
}
.apps-section__summary.d-desktop{
    display: none;
}
@media(min-width:768px){
    .apps-section__summary.d-mobile{
        display: none;
    }
    .apps-section__summary.d-desktop{
        display: block;
        margin-bottom: 16px;
    }
    .apps-section__title.p-absolute{
        position: absolute;
    }
    .apps-section__wrapper .react-tabs__tab-list{
        text-align: right;
    }

}