.container-divided__author-section {
    background-color: #EBEFF3;
    border-radius: 10px;
    padding: 20px;
}
#author-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 70%;
}
@media only screen and (max-width: 768px) {
    #author-profile{
        width: 100%;
    }
    .author-page__profile-link {
        font-size: 15px;
    }
}
.author-page__profile-picture {
    width: 84px;
    height: 84px;
    background-size: cover;
    border-radius: 50%;
    border: 5px solid #ccc;
}

.author-page__profile-link-container{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 15px;
}

.author-page__profile-link {
    margin-right: 4px;
}

.author-page-icon {
    margin-right: 6px
}