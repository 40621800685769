.breadcrumbs-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.breadcrumb-with-dropdown {
  position: relative;
  cursor: pointer;
  /* padding-right: 20px; */
  text-transform: capitalize;
}

.breadcrumb-with-dropdown_arrow {
  /* background: url(@assets/images/dropdownArrow.svg) no-repeat center center; */
  /* width: 9px; */
  /* height: 5px; */
  transition: 0.2s ease;
  padding: 12px 15px;
  margin-left: 0px;
  margin-right: -12px;
}

.breadcrumb-with-dropdown_arrow::before {
  content: "\e907";
  font-family: 'icomoon' !important;
  color: #AEB6BF;
}

.breadcrumb-with-dropdown_arrow.active::before {
  display: inline-block;
  transform: scaleY(-1);
  content: "\e907";
  font-family: 'icomoon' !important;
  color: #AEB6BF;
}

.breadcrumb-with-dropdown_arrow.active {
  transform: rotate(180deg);
}

.breadcrumbs {
  display: flex;
  user-select: none;
}

.breadcrumbs a {
  color: var(--primary-red-color);
  transition: var(--transiton);
}

.breadcrumbs li {
  margin-left: 32px;
  position: relative;
}

.breadcrumbs li:first-child:before {
  left: -21px;
}

.breadcrumbs li:before {
  content: "\e90a";
  font-family: 'icomoon' !important;
  font-size: 10px;
  font-weight: normal;
  color: #b8c3cf;
  position: absolute;
  left: -18px;
}

.breadcrumbs a:hover {
  opacity: 0.7;
}

.breadcrumbs-logo_wrapper a {
  display: flex;
}

.breadcrumbs-logo_wrapper img {
  min-width: 90px;
}

.breadcrumb-current {
  color: var(--caption-color);
}
