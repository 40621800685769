.video-category__bottom-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.video-category__breadcrumbs .breadcrumbs-wrapper {
  margin-bottom: 24px;
}

.video-category__bottom-wrapper .video-section__item {
  width: calc((100% - 16px) / 2);
  margin-right: 16px;
}

.video-category__bottom-wrapper .video-section__item:nth-child(2n) {
  margin-right: 0;
}

@media (min-width: 768px) {
  .video-category__bottom-wrapper .video-section__item:nth-child(2n) {
    margin-right: 16px;
  }

  .video-category__bottom-wrapper .video-section__item:nth-child(3n) {
    margin-right: 0;
  }

  .video-category__bottom-wrapper {
    margin-top: 32px;
  }

  .video-category__bottom-wrapper .video-section__item {
    width: calc((100% - 32px) / 3);
  }

  .video-category__bottom-wrapper .video-section__item:nth-child(n + 7) {
    display: block;
  }
}
