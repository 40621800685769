.fixed-banner-mobile__wrapper,
.ads-sticky-bottom-mobile__wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11111;
  max-height: 100px;
  overflow: auto;
  text-align: center;
}

.ads-sticky-bottom-mobile__wrapper.d-none{
  display: none;
}

.fixed-banner-mobile__wrapper.d-none{
  display: none;
}

.fixed-banner-mobile__img {
  width: 100%;
}

.fixed-banner-mobile__close {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

@media screen and (min-width: 768px) {
  .fixed-banner-mobile__wrapper {
    display: none;
  }
}
