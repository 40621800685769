.search-page__result {
  color: var(--text-color);
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;
}

.search-page__top-banner {
  margin-bottom: 16px;
}

.search-page__red {
  color: var(--primary-red-color);
}

.search-page__search-result-number {
  margin-right: 5px;
}

.search-page__main-title {
  margin-bottom: 16px;
}

.search-page__main-title .title-large {
  font-size: 20px;
  line-height: 24px;
}

.search-page__main-title .sub-title__image {
  width: 20px;
}

.search-article-page__search-results .post-block-with-category__img {
  min-width: 84px;
  width: 84px;
  min-height: 84px;
  height: 84px;
  object-fit: cover;
}

.search-article-page__search-results .post-block-with-category__img-wrapper {
  margin-right: 8px;
}

.search-article-page__search-results .post-block-with-category__text {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}

.search-article-page__search-results .post-info__publish-date {
  display: none;
}

#searchPage .infinite-scroll-block{
  min-height: 100vh;
}

@media (min-width: 768px) {
  .search-page__main-title .title-large {
    font-size: 28px;
    line-height: normal;
  }

  .search-page__main-title .sub-title__image {
    width: 32px;
    width: 32px;
  }

  .search-page__top-banner {
    margin-bottom: 24px;
  }

  .search-page__search-result-number {
    margin-right: 6px;
  }

  .search-page__result {
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    text-align: left;
    margin-top: 24px;
  }

  .search-article-page__search-results .post-block-with-category__img {
    width: 183px;
    min-width: 183px;
    height: 120px;
    min-height: 120px;
  }

  .search-article-page__search-results .post-block-with-category__text {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
  }

  .search-article-page__search-results .post-info__publish-date {
    display: block;
  }

  .search-article-page__search-results .post-block-with-category__img-wrapper {
    margin-right: 24px;
  }
}
