.posts-container__wrapper {
  display: flex;
  flex-direction: column;
}

.posts-container__left {
  max-width: 100%;
  margin-bottom: 32px;
}

.posts-container__outer {
  margin-bottom: 32px;
}

.posts-container__outer-video-icons .post-block-large__img-wrapper {
  position: relative;
  display: inline-block;
}

.posts-container__outer-video-icons .post-block-large__img-wrapper:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/video-icon2.svg);
  width: 60px;
  height: 42px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: var(--transiton);
}

.posts-container__outer-video-icons .post-block-large__img-wrapper:hover:after {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .posts-container__outer-video-icons .post-block-large__img-wrapper:after {
    background: url(../../assets/images/play-video-icon.svg);
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 992px) {
  .posts-container__wrapper {
    justify-content: space-between;
    flex-direction: row;
  }

  .posts-container__left {
    max-width: 50%;
    margin-right: 24px;
    margin-bottom: 0;
  }
}
@media (min-width: 1400px) {
  .posts-container__left {
    max-width: 400px;
  }
}
