.sidebar-item__container {
   font-weight: bold;
   font-size: 14px;
   line-height: 1;
   display: flex;
   align-items: center;
   padding: 20px;
   min-width: 143px;
   border-radius: 12px;
   margin-top: 20px;
   border: 1px solid #636C77;
}

.sidebar-item__container.active{
   background: var(--primary-red-color);
   border: 1px solid var(--primary-red-color);
}

.sidebar-item__container.active .title-text h3,.sidebar-item__container.active .title-text span{
   color: white;
}

.eula__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   color: #636C77;
}
.contact__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   color: #636C77;
}
.terms-conditions__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   color: #636C77;
}
.faq__wrapper, .disclaimer__wrapper, .pedoman__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   color: #636C77;
}
.eula__wrapper .title-text,
.terms-conditions__wrapper .title-text {
   margin: 0 30px 0 0;
}
.contact__wrapper .title-text {
   margin: 0 45px 0 0;
}
.faq__wrapper .title-text {
   margin: 0 65px 0 0;
}

.disclaimer__wrapper .title-text {
   margin: 0 65px 0 0;
}

.pedoman__wrapper .title-text {
   margin: 0 65px 0 0;
}

.eula__wrapper .title-text h3,
.contact__wrapper .title-text h3,
.terms-conditions__wrapper .title-text h3,
.faq__wrapper .title-text h3, 
.disclaimer__wrapper .title-text h3,
.pedoman__wrapper .title-text h3{
   color: #636C77;
   font-weight: 700;
   font-size: 16px;
   align-items: center;
   justify-content: center;
}

.eula__wrapper .title-text span,
.contact__wrapper .title-text span,
.terms-conditions__wrapper .title-text span,
.faq__wrapper .title-text span, 
.disclaimer__wrapper .title-text span,
.pedoman__wrapper .title-text span{
   font-size: 10px;
   color: #636C77;
}

.twitter__wrapper, .twitter__wrapper .twitter-icon,
.call__wrapper, .call__wrapper .call-icon {
   min-height: 30px;
   min-width: 100%;
}
.twitter__wrapper .twitter-icon {
   text-align: center;
   font-size: 32px;
}
.twitter__wrapper .twitter-text,
.call__wrapper .call-text {
   padding: 30px 0 10px 0;
   text-align: center;
}
.twitter__wrapper .twitter-text h3,
.call__wrapper .call-text h3 {
   font-size: 17px;
   font-weight: 700;
   text-align: center;
   color: #636C77;
   margin-bottom: 8px;;
}
.twitter__wrapper .twitter-text span,
.call__wrapper .call-text span {
   font-size: 10px;
   font-weight: normal;
}
.twitter__wrapper .twitter-button,
.call__wrapper .call-button {
   background: var(--primary-red-color);
   border-radius: 30px;
   text-align: center;
   text-transform: capitalize;
   cursor: pointer;
   padding: 16px 0;
   margin-top: 15px;
   width: 50%;
   color: white;
   border: none;
   font-weight: 700;
   font-size: 14px;
   position:relative;
   left: 25%;
   display: block;
}

.call__wrapper .call-icon {
   background: url(@assets/images/static/phone-call.svg) top center no-repeat;
}

.call__wrapper .email-icon {
   text-align: center;
}

@media only screen and (max-width: 576px) {
   .eula__wrapper .title-text,
   .terms-conditions__wrapper .title-text {
      margin: 0 64px 0 0;
   }
   .contact__wrapper .title-text {
      margin: 0 79px 0 0;
   }
   .faq__wrapper .title-text {
      margin: 0 99px 0 0;
   }
   .disclaimer__wrapper .title-text {
      margin: 0 99px 0 0;
   }
   .pedoman__wrapper .title-text {
      margin: 0 99px 0 0;
   }
}

.sidebar-item__container span{
   font-weight: 600;
   font-size: 28px;
}

.sidebar-item__container.active span.icon-icon-arrow-sm:before{
   color: #fff ;
}

.sidebar-item__container.active span{
   color: #fff ;
}