.category-page__breadcrumbs .breadcrumb-current {
  display: none;
}

.container-divided.tags-page {
  padding: 0;
}

.tags-page .infinite-scroll-block{
  padding: 0 16px;
}

@media (min-width: 768px) {
  .tags-page .infinite-scroll-block{
    padding: 0;
  }
}