.lazy-loader__wrapper {
  padding: 15px 10px;
  margin-bottom: 20px;
}

.skeleton-item {
  display: flex;
  align-items: center;
}

.skeleton-square {
  width: 70px;
  height: 70px;
  background: var(--frame-color);
  margin-right: 15px;
}

.skeleton-rows {
  flex-grow: 1;
}

.skeleton-row {
  width: 80%;
  height: 7px;
  background: var(--frame-color);
}

.skeleton-row:nth-child(1) {
  width: 100%;
}

.skeleton-row:not(:last-child) {
  margin-bottom: 10px;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
}

.skeleton-box:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
