.top-component__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;
}

.top-component__wrapper .editors-pick__item {
  width: calc((100% - 16px)/2);
}

.top-component__left {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex:1;
}

.top-component__right {
  width: 580px;
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}


/* @media (min-width: 576px) {
  .top-component__wrapper {
    margin-bottom: 48px;
  }

  .top-component__wrapper .editors-pick__item {
    width: 280px;
    height: 250px;

  }

  .top-component__wrapper .editors-pick__item {
    margin-bottom: 24px;
  }

  .top-component__wrapper .editors-pick__item:last-child {
    margin-bottom: 0;
  }
} */

@media (min-width: 768px) {
  .top-component__wrapper {
    flex-direction: row;
    margin-bottom: 48px;
  }

  .top-component__left {
    margin-right: 16px;
    margin-bottom: 0;
  }

  .top-component__right {
    flex-direction: column;
    width: initial;
  }
  
  .top-component__right .editors-pick__item {
    width: 280px;
    height: 250px;
    margin-bottom: 24px;
  }
    
  .top-component__right .editors-pick__item:last-child {
    margin-bottom: 0;
  }
} 

@media (min-width: 992px) {
  .editors-pick__wrapper .editors-pick__item {
    width: calc((100% - 40px) / 3);
  }
}