.vehicle-full-specs-wrapper .full-spec__title {
    color: #212936;
    font-size: 24px;
    font-weight: 700;
}

.vehicle-full-specs-wrapper hr {
    margin-top: 25px;
    margin-bottom: 5px;
}

.vehicle-sub-item__header .icon-icon-arrow-up-fill-sm-margin::before {
    color: #CE1818;
}

.vehicle-sub-item__header .icon-icon-arrow-fill-sm-margin::before {
    color: #CE1818;
}

.vehicle-sub-item__header i {
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 10px;
}

.vehicle-subitem__name {
    color: #212936;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
}


.vehicle-subitem-container__info .vehicle-subitem__text {
    width: 200px;
    color: #AEB6BF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.vehicle-subitem-container__info .vehicle-subitem__value {
    color: #212936;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    flex: 1;
}

.vehicle-subitem-container .vehicle-subitem-container__info:nth-child(odd){
    background: #AEB6BF4F;
}