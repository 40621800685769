.video-top-block {
  margin-bottom: 8px;
}

.video-top-block__wrapper {
  display: flex;
  flex-direction: column;
}

.video-top-block__wrapper .video-section__item-title {
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
}

.video-top-block__wrapper .video-section__item {
  width: 100%;
}

@media (min-width: 576px) {
  .video-top-block__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .video-top-block__wrapper .video-section__item {
    width: calc((100% - 20px) / 2);
  }
}

@media (min-width: 768px) {
  /* .video-top-block .video-section__item-img-link {
    height: 270px;
  }
  .video-top-block .video-section__item-img {
    object-fit: cover;
  } */
  .video-top-block__wrapper {
    margin-bottom: 16px;
  }

  .video-top-block__wrapper .video-section__item-title {
    font-size: 20px;
    line-height: 24px;
  }
  .video-top-block {
    margin-bottom: 0;
  }
}
