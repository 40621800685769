.button-slider__wrapper{
    margin-bottom: 16px;
}

.button-slider__wrapper .slick-disabled {
    display: none !important;
}

.button-slider__wrapper .slick-next,
.button-slider__wrapper .slick-prev {
    height: 100%;
    background: #636c7791;
    width: 48px;
    z-index: 11;
    border-radius: 4px;
}

.button-slider__wrapper .slick-next {
    right: 4px;
}

.button-slider__wrapper .slick-prev {
    left: -1px;
}

.button-slider__wrapper .slick-next:before {
    content: "\e909";
    color: #ebeff3;
    font-family: 'icomoon' !important;
    font-size: 25px;
    -webkit-font-smoothing: antialiased;
}

.button-slider__wrapper .slick-prev:before {
    transform: scaleX(-1);
    display: inline-block;
    content: "\e909";
    color: #ebeff3;
    font-family: 'icomoon' !important;
    font-size: 25px;
    -webkit-font-smoothing: antialiased;
}

.button-slider__wrapper .slick-next {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.button-slider__wrapper .slick-prev {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}