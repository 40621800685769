.other-recommendation-title{
    color: #212936;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}

.other-recommendation__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media(min-width:590px){
    .other-recommendation__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill,  calc((100% - 16px) / 2));
        justify-content: space-between;
        grid-gap: 8px;
    }

    .other-recommendation__wrapper .tab-content-item__wrapper{
        width:100%;
    } 
}

@media(min-width:768px){
    .other-recommendation-title{
        font-size: 24px;
        line-height: 33px;
    }
    .other-recommendation__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);
        justify-content: space-between;
        grid-gap: 20px;
    }
}