.popular-today__item-ttl {
  color: var(--text-color);
  line-height: normal;
  transition: var(--transiton);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 16px;
}

.popular-today__item-ttl:hover {
  opacity: 0.7;
}

@media (min-width: 576px) {
  .popular-today__item {
    padding: 16px 0 16px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid var(--line-color);
  }

  .popular-today__item-num {
    margin: auto 0px;
    font-size: 36px;
    font-family: "Open Sans";
    position: relative;
    display: inline-block;
    white-space: nowrap;
    left: -16px;
  }
}
