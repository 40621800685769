.category-block__outer {
  padding: 0 16px;
}

@media (min-width:768px) {
  .category-block__outer {
    padding: 0;
  }

}

/* ============================== */

.category-block__wrapper {
  display: flex;
  flex-direction: column;
}

.category-block__left {
  max-width: 100%;
  margin-bottom: 32px;
}

.category-block__outer {
  margin-bottom: 32px;
}

.category-block__outer-video-icons .category-block-left__img-wrapper {
  position: relative;
  display: inline-block;
}

.category-block__outer-video-icons .category-block-left__img-wrapper:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/video-icon2.svg);
  width: 60px;
  height: 42px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: var(--transiton);
}

.category-block__outer-video-icons .category-block-left__img-wrapper:hover:after {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .category-block__outer-video-icons .category-block-left__img-wrapper:after {
    background: url(../../assets/images/play-video-icon.svg);
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 992px) {
  .category-block__wrapper {
    justify-content: space-between;
    flex-direction: row;
  }

  .category-block__left {
    max-width: 50%;
    margin-right: 15px;
    margin-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .category-block__left {
    max-width: 400px;
  }
}

/* ============================== */

.category-block-right {
  display: flex;
  align-items: flex-start;
}

.category-block-right:not(:last-child) {
  margin-bottom: 25px;
}

.category-block-right__img {
  min-width: 100px;
  width: 100px;
  border-radius: 4px;
  max-height: 72px;
}

.category-block-right__img-wrapper {
  margin-right: 16px;
}

.category-block__outer-video-icons .category-block-right__img-wrapper {
  position: relative;
  display: flex;
}

.category-block__outer-video-icons .category-block-right__img-wrapper:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/video-icon2.svg) no-repeat;
  width: 40px;
  height: 28px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: var(--transiton);
  background-size: contain;
}

.category-block__outer-video-icons .category-block-right__img-wrapper:hover:after {
  opacity: 0.6;
}

.category-block-right__text {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}

.category-block-right__link {
  color: var(--text-color);
  transition: var(--transiton);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}

.category-block-right__link:hover {
  opacity: 0.6;
}

@media screen and (min-width: 768px) {
  .category-block__outer-video-icons .category-block-right__img-wrapper:after {
    background: url(../../assets/images/play-video-icon.svg);
    width: 32px;
    height: 32px;
    background-size: contain;
  }

  .category-block-right:not(:last-child) {
    margin-bottom: 15px;
  }

  .category-block-right__img-wrapper {
    margin-right: 20px;
  }

  .category-block-right__img {
    min-width: 100px;
    width: 100px;
    min-height: 70px;
    object-fit: cover;
  }

  .category-block-right__text {
    font-size: 16px;
    line-height: initial;
    margin-bottom: 8px;
  }

  .category-block__outer-video-icons .category-block-right__img {
    min-width: 156px;
    width: 156px;
  }
}

@media screen and (min-width: 992px) {
  .category-block__outer-video-icons .category-block-right__img {
    min-width: 100px;
    width: 100px;
  }

  .category-block__right .category-block-right__img {
    min-width: 100px;
    width: 100px;
    height: 72px;
  }
}

@media screen and (min-width: 1400px) {
  .category-block__outer-video-icons .category-block__left {
    max-width: 400px;
  }

  .category-block__outer-video-icons .category-block-right__img {
    min-width: 156px;
    width: 156px;
  }
}

/* ============================== */

.category-block-left__img {
  width: 479px;
  border-radius: 8px;
}

.category-block-left__title {
  margin-bottom: 8px;
}

.category-block-left__link {
  color: var(--text-color);
  transition: var(--transiton);
}

.category-block-left__link:hover {
  opacity: 0.6;
}

@media (min-width: 576px) {
  .category-block-left__img {
    margin-bottom: 16px;
    max-width: 500px;
    width: 479px;
  }
}

@media (min-width: 768px) {
  .category-block-left__title {
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
  }
}

@media (min-width: 992px) {
  .category-block-left__img {
    max-width: 100%;
  }
}

/* ============================== */

.category-block__bottom-wrapper{
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-block__bottom-button {
  background: linear-gradient(93.14deg, #CE1818 32.01%, #FFE660 149.92%);
  border-radius: 8px;
  padding: 8px 66px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF; 
}