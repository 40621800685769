.share-social__container {
  width: 100%;
  background: var(--frame-color);
  padding: 0 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.share-social__title {
  margin-right: 16px;
  color: var(--caption-color);
  font-weight: 600;
  flex: none;
  font-size: 16px;
  line-height: 24px;
}

.share-social__social-wrapper {
  --gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.share-social__social-item {
  display: block;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 6px;
  display: grid;
  place-items: center;
  transition: var(--transition);
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 24px;
}

.share-social__social-item:not(:last-child) {
  margin-right: 10px;
}

.share-social__social-item:hover {
  opacity: 0.6;
}

.share-desk__container {
  display: none;
}
.share-desk__icon {
  display: none;
}

.alert-copy-clip{
  position: fixed;
  bottom: -40px;
  background: var(--primary-red-color);
  color:white;
  border-radius: 12px;
  padding: 4px 8px;
  width: 240px;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom .25s linear;
  z-index: 2;
}

.alert-copy-clip.show{
  display: block;
  bottom: 32px;
}

.alert-copy-clip .close-btn{
  float: right;
  cursor: pointer;
}

@media (min-width: 768px) {
  .share-social__container {
    display: none;
    margin-bottom: 0;
  }

  .share-desk__container {
    display: block;
    margin: 10px;
    position: relative;
    height: 60px;
  }

  .share-desk__icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid var(--line-color);
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 11111;
    cursor: pointer;
  }

  .share-desk__icon:hover {
    box-shadow: 4px 4px 8px rgba(184, 195, 207, 0.35);
    cursor: pointer;
  }

  .share-desk__icon:hover + .share-desk__social-outer-wrapper {
    display: block;
  }

  .share-desk__social-outer-wrapper {
    display: none;
    top: 0;
    padding-top: 60px;
    position: absolute;
    z-index: 1111;
  }

  .share-desk__social-outer-wrapper:hover {
    display: block;
  }

  .share-desk__social-wrapper {
    width: 62px;
    background: #fff;
    filter: drop-shadow(4px 4px 8px rgba(184, 195, 207, 0.35));
    border-radius: 8px;
    border: 1px solid var(--line-color);
    margin-top: 12px;
    animation: growDown 400ms ease-in-out forwards;
    transform-origin: top center;
    font-size: 28px;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .share-desk__social-wrapper:before,
  .share-desk__social-wrapper:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .share-desk__social-wrapper:before {
    top: -13px;
    border-bottom: 13px solid var(--line-color);
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
  }

  .share-desk__social-wrapper:after {
    top: -12px;
    border-bottom: 12px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }

  .share-desk__social-item {
    width: 60px;
    height: 60px;
    display: grid;
    place-items: center;
    transition: var(--transition);
    cursor: pointer;
  }

  .share-desk__social-item:hover {
    opacity: 0.6;
  }

  .share-desk__social-item {
    border-bottom: 0.5px solid var(--line-color);
  }

  .share-desk__social-item img {
    width: 28px;
  }
}
