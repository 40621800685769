.see-all__btn {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: var(--text-color);
  border: 1px solid var(--neutral-color);
  border-radius: 8px;
  background: transparent;
  padding: 2px 0;
  min-width: 138px;
  display: block;
  margin: auto;
  cursor: pointer;
  transition: var(--transition);
}

.see-all__btn:hover {
  color: rgba(33, 41, 54, 0.6);
}

@media (min-width: 768px) {
  .see-all__btn {
    width: 100%;
    max-width: 384px;
    padding: 7px 0;
  }
}
