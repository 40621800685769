@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3o78n3');
  src:  url('fonts/icomoon.eot?3o78n3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3o78n3') format('truetype'),
    url('fonts/icomoon.woff?3o78n3') format('woff'),
    url('fonts/icomoon.svg?3o78n3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vehicle-announce:before {
  content: "\e92c";
  color: #fff;
}
.icon-vehicle-battery:before {
  content: "\e92d";
  color: #fff;
}
.icon-vehicle-charge:before {
  content: "\e92e";
  color: #fff;
}
.icon-vehicle-link:before {
  content: "\e931";
  color: #fff;
}
.icon-vehicle-power:before {
  content: "\e932";
  color: #fff;
}
.icon-vehicle-road:before {
  content: "\e933";
  color: #fff;
}
.icon-vehicle-speed:before {
  content: "\e934";
  color: #fff;
}
.icon-vehicle-type:before {
  content: "\e935";
  color: #fff;
}
.icon-team-icon:before {
  content: "\e92b";
}
.icon-icon-web3-m:before {
  content: "\e900";
  color: #eceff4;
}
.icon-icon-partnership-m:before {
  content: "\e901";
  color: #ce1818;
}
.icon-web3-d:before {
  content: "\e902";
  color: #4d555f;
}
.icon-icon-electric-car-m:before {
  content: "\e903";
  color: #eceff4;
}
.icon-ElectricCar-sm:before {
  content: "\e91f";
  font-size: 120px;
}
.icon-ElectricMoped-sm:before {
  content: "\e921";
  font-size: 120px;
}

.icon-archive-data-document-sm:before {
  content: "\e91e";
  font-size: 120px;
}
.icon-archive-data-document-md:before {
  content: "\e91e";
  font-size: 150px;
}
.icon-archive-data-document-lg:before {
  content: "\e91e";
  font-size: 200px;
}
.icon-partnership:before {
  content: "\e923";
}
.icon-accept:before {
  content: "\e924";
}
.icon-advertisement:before {
  content: "\e925";
}
.icon-ElectricCar:before {
  content: "\e91f";
  color: #4d545e;
}
.icon-ElectricMoped:before {
  content: "\e921";
  color: #4d545e;
}
.icon-archive-data-document:before {
  content: "\e91e";
  color: #3d3d63;
}
.icon-icon-battery:before {
  content: "\e904";
  color: #fff;
}
.icon-icon-arrow-down:before {
  content: "\e905";
  color: #aeb6bf;
}
.icon-icon-arrow-down-margin:before {
  content: "\e906";
  color: #aeb6bf;
}
.icon-icon-arrow-fill-sm-margin:before {
  content: "\e907";
  color: #aeb6bf;
}
.icon-icon-arrow-up-fill-sm-margin:before {
  content: "\e91c";
  color: #aeb6bf;
}
.icon-icon-arrow-left:before {
  content: "\e908";
  color: #d6dee3;
}
.icon-icon-arrow-lg:before {
  content: "\e909";
  color: #ebeff3;
}
.icon-icon-arrow-sm:before {
  content: "\e90a";
  color: #b8c3cf;
}
.icon-icon-article:before {
  content: "\e90b";
}
.icon-icon-double-arrow:before {
  content: "\e90c";
  color: #ce1818;
  font-size: 25px;
}
.icon-icon-download:before {
  content: "\e90d";
  color: #ebeff3;
}
.icon-icon-smartphone:before {
  content: "\e90e";
  color: #ebeff3;
}
.icon-icon-video:before {
  content: "\e90f";
  color: #ebeff3;
}
.icon-icon-zoom:before {
  content: "\e910";
  color: #aeb6bf;
}
.icon-img-smartphone-lg:before {
  content: "\e911";
}
.icon-img-tablet-lg:before {
  content: "\e912";
}
.icon-game:before {
  content: "\e91d";
  color: #212936;
}
.icon-logo-jt:before {
  content: "\e913";
  color: #636c77;
}
.icon-kcon-hamburger:before {
  content: "\e914";
}
.icon-social-email:before {
  content: "\e915";
  font-size: 30px;
  color: #2962ff;
}
.icon-social-email.white:before {
  color: white;
}
.icon-social-fb:before {
  content: "\e916";
  font-size: 30px;
}
.icon-social-fb.white:before {
  color: white;
}
.icon-social-fb.default:before {
  color: #6078ab;
}

.icon-social-ig:before {
  content: "\e917";
  font-size: 30px;
}
.icon-social-line:before {
  content: "\e918";
  color: #4eda83;
}
.icon-social-share:before {
  content: "\e919";
}
.icon-social-twitter:before {
  content: "\e91a";
  color: #00aaec;
}
.icon-social-url:before {
  content: "\e91b";
}
.icon-social-whatsapp:before {
  content: "\e920";
  color: #4eda83;
}
.icon-newspaper:before {
  content: "\e922";
}
.icon-camera:before {
  content: "\e926";
}
.icon-play:before {
  content: "\e927";
}
.icon-books:before {
  content: "\e928";
}
.icon-file-text:before {
  content: "\e929";
}
.icon-file-text2:before {
  content: "\e92a";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-search:before {
  content: "\e986";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-meter:before {
  content: "\e9a6";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-power-cord:before {
  content: "\e9b7";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
  font-size: 30px;
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-twitter:before {
  content: "\ea96";
  font-size: 30px;
  margin-top: 15px!important;
}
.icon-youtube:before {
  content: "\ea9d";
  font-size: 30px;
  margin-top: 50px;
}

/* CUSTOM CSS */

.icon-ElectricCar-sm:before {
  content: "\e91f";
  font-size: 120px;
}
.icon-ElectricMoped-sm:before {
  content: "\e921";
  font-size: 120px;
}

.icon-archive-data-document-sm:before {
  content: "\e91e";
  font-size: 120px;
}
.icon-archive-data-document-md:before {
  content: "\e91e";
  font-size: 150px;
}
.icon-archive-data-document-lg:before {
  content: "\e91e";
  font-size: 200px;
}

.icon-img-smartphone-sm:before {
  content: "\e911";
  font-size: 120px;
}
.icon-img-smartphone-md:before {
  content: "\e911";
  font-size: 150px;
}
.icon-img-smartphone-lg:before {
  content: "\e911";
  font-size: 200px;
}
.icon-img-tablet-sm:before {
  content: "\e912";
  font-size: 120px;
}
.icon-img-tablet-md:before {
  content: "\e912";
  font-size: 150px;
}
.icon-img-tablet-lg:before {
  content: "\e912";
  font-size: 200px;
}

.icon-game-sm:before {
  content: "\e91d";
  font-size: 120px;
}
.icon-game-md:before {
  content: "\e91d";
  font-size: 150px;
}
.icon-game-lg:before {
  content: "\e91d";
  font-size: 200px;
}

.icon-partnership-md:before {
  content: "\e923";
  font-size: 150px;
}
.icon-advertisement-lg:before {
  content: "\e925";
  font-size: 200px;
}
.icon-accept-lg:before {
  content: "\e924";
  font-size: 200px;
}
.icon-web3-d-sm:before {
  content: "\e902";
  /* color: #4d555f; */
  font-size: 120px;
}
.icon-social-email-default:before{
  content: "\e915";
  color: inherit;
}
.icon-instagram.colorized:before {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-youtube.red:before {
  color: #f00;
}
.icon-youtube.white:before {
  color: white;
}
.icon-facebook-white:before {
  content: "\ea90";
  color: white;
}
.icon-instagram.white:before {
  color:white;
}
.icon-twitter:before {
  color:white;
}
.icon-icon-arrow-down.black:before {
  color: black;
}