.container-divided__article-page {
  padding: 0;
}

.article-top-info__wrapper {
  padding: 0 16px;
}

.article-top-info__wrapper .post-info__wrapper {
  margin-bottom: 16px;
}

/* .article-main-content__wrapper {
  padding: 0 16px;
} */
.article-main-content__soc-and-banner .banner-mobile {
  margin-bottom: 40px;
}

.article-top-info__wrapper .breadcrumb-current {
  display: none;
}

.article-main-content__relate-topics {
  padding: 0 16px;
}

.article-main-content__wrapper .tags-wrapper {
  padding: 0 16px;
  /* margin-top: 24px; */
}

.article-main-content__wrapper .article-main-content__inner {
  padding: 0 16px;
}

.article-main-content__wrapper .article-main-content__banner {
  padding: 0 16px;
}

.article-main-content__wrapper .create-account-container {
  display: block;
  margin-bottom: 0;
}

.article-page__sidebar-banner1 {
  margin-bottom: 24px;
}

.article-page__sidebar-banner2 {
  margin-bottom: 40px;
}

.popular-today__container {
  margin-bottom: 24px;
}

.article-page__recent-posts {
  margin-top: 24px;
}

.main-container__article-page .posts-container__outer {
  padding: 0 16px;
}

.article-page__horizontal-banner1 {
  margin-bottom: 36px;
}

.main-container__article-page .infinite-scroll-block {
  padding: 0 16px;
}

.main-container__article-page p code {
  text-align: center;
  position: relative;
  display: block;
  padding: 10px 25px;
  padding-right: 25px;
  margin: 30px 15px;
  border-radius: 15px;
  font-family: Open Sans;
  font-size: 15px;
  color: #654445;
  background-color: #fff7f7;
  word-wrap: break-word;
}

.main-container__article-page p code:before {
  content: "\201C";
  left: -13px;
  top: 29px;
}

.main-container__article-page p code:after {
  content: "\201D";
  right: -15px;
  bottom: -46px;
}

.main-container__article-page p code:after, p code:before {
  position: absolute;
  opacity: .5;
  font-size: 140px;
  font-family: Georgia,Times New Roman,Times,serif;
}

.main-container__article-page .spotify-container iframe {
  display: block;
  margin: auto;
}

@media (min-width: 768px) {
  .main-container__article-page .infinite-scroll-block {
    padding: 0;
  }

  .main-container__article-page .recent-posts__bottom {
    padding: 0;
  }

  .main-container__article-page .posts-container__outer {
    padding: 0;
  }

  .article-page__recent-posts {
    margin-top: 40px;
  }

  .main-container__article-page .follow-us-widget__container {
    margin-bottom: 24px;
  }

  .article-main-content__wrapper .create-account-container {
    display: none;
  }

  .article-main-content__wrapper .article-main-content__banner {
    padding: 0;
  }

  .article-main-content__wrapper .article-main-content__inner {
    padding: 0;
  }

  .article-main-content__wrapper .tags-wrapper {
    padding: 24px 0 0 0;
    padding-top: 24px !important;
    /* margin-top: 40px; */
    border-top: 1px solid var(--line-color);
  }

  .article-main-content__relate-topics {
    padding: 0;
  }

  .article-main-content__soc-and-banner {
    display: none;
  }

  .article-main-content__wrapper {
    padding: 24px 0 0 0;
  }

  .article-main-content__padding {
    padding-left: 100px;
  }

  /* .container-divided__article-page {
    padding: 0 16px;
  } */

  .article-top-info__wrapper {
    padding: 0 16px;
  }
  .container-divided__article-page {
    padding: 0 16px;
  }

  .article-top-info__wrapper .breadcrumb-current {
    display: block;
  }

  .article-main-content__tags-banner {
    margin: 0 0 30px 0;
  }
}
