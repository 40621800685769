.apps-article_wrapper {
    background: var(--text-color);
    padding: 29px 24px 50px 24px;
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
}

.apps-article_wrapper .post-slider__one-slide-title {
    color: var(--frame-color);
}

.apps-article_wrapper .sub-title__wrapper {
    margin-bottom: 26px;
}

.apps-article_wrapper .posts-slider_wrapper .slick-next{
    right: -2px;
}

@media (min-width: 992px) {
    .apps-article_wrapper {
        max-width: 980px;
    }
}
