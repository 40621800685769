.article-top-info__wrapper {
  padding-bottom: 16px;
}

.article-top-info__breadcrumbs {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .article-top-info__title {
    font-size: 32px;
  }

  .article-top-info__wrapper {
    border-bottom: 1px solid var(--line-color);
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .article-top-info__title {
    font-size: 24px !important;
  }
}
