.dApps-headline__container {
  position: relative;
}

.dApps-headline__wrapper {
  display: flex;
  height: 350px;
}

.dApps-headline__left-blur {
  display: none;
  position: absolute;
  z-index: 1000;
  height: 400px;
  width: 165px;
  background: linear-gradient(
    90deg,
    #ffffff 48.96%,
    rgba(255, 255, 255, 0) 100%
  );
  left: -40px;
}
.dApps-headline__right-blur {
  display: none;
  position: absolute;
  z-index: 1000;
  right: 0px;
  height: 400px;
  width: 165px;
  background: linear-gradient(
    -90deg,
    #ffffff 48.96%,
    rgba(255, 255, 255, 0) 100%
  );
}
/* styling dots and arrow */

.react-multi-carousel-dot button {
  display: none;
}

.dapps-dot-list {
  bottom: 0px;
}

.dapps-dot-list .react-multi-carousel-dot {
  background: #636c77;
  opacity: 0.5;
  border-radius: 20px;
  height: 10px;
  width: 10px;
  margin: 0 2px;
}

.dapps-dot-list .react-multi-carousel-dot--active {
  background: #ce1818;
  opacity: 1;
}

.react-multiple-carousel__arrow {
  display: none;
}

.react-multiple-carousel__arrow--right {
  border-radius: 8px 0 0 8px;
}

.react-multiple-carousel__arrow--left {
  border-radius: 0 8px 8px 0;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.dApps-headline__wrapper .card-item {
  /* width: 350px !important; */
}

.container > .react-multi-carousel-track {
  /* transform: translate3d(-1330.5px, 0px, 0px) !important; */
}

/* styling dots and arrow ends*/

@media (min-width: 768px) {
  .dApps-headline__wrapper .react-multiple-carousel__arrow {
    height: 200px;
    display: block;
  }

  .dapps-dot-list .react-multi-carousel-dot {
    width: 20px;
  }

  .dApps-headline__left-blur,
  .dApps-headline__right-blur {
    display: block;
  }
}
