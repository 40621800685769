li.react-multi-carousel-item--active:first-child {
  transform: scale(1);
  margin: auto;
}
li.react-multi-carousel-item--active:not(:first-child):not(:last-child) {
  width: 340px;
}

.dApps-section__title .sub-title__text {
  text-transform: capitalize;
}

.react-multi-carousel-list {
  height: 100%;
  padding: 0;
}

.dApps-headline__item {
  position: relative;
  margin: 0 4px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2));
  border-radius: 25px;
  margin: 0 10px;
  min-width: 160px;
}

.dApps-image__wrapper {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  min-height: 80px;
}
.dApps-image__wrapper img {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  object-fit: cover;
  height: 190px;
}

.dApps-headline__info {
  text-align: center;
  background: #ffffff;
  min-height: 100px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transform: translateX(0px);
}
.dApps-headline__title {
  font-weight: bold;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.dApps-caption__title {
  margin-top: 10px;
  padding: 0 10px;
}

.react-multi-carousel-item--active {
  /* margin: -6px 12px 0px 12px; */
}

.dApps-image__wrapper::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.dApps-headline__item:hover .apps-image__wrapper::before {
  /* background: linear-gradient( 180deg, rgba(206, 24, 24, 0) 0%, rgba(206, 24, 24, 0.24) 51.04%, var(--primary-red-color) 80.73%); */
}

.dApps-headline__item img {
  width: 100%;
}

.image-item {
  padding: 20px 0 20px 20px;
}

@media (min-width: 500px) {
  .dApps-headline__item {
    width: auto;
  }
  .dApps-headline__title {
    /* margin-top: 4px; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .dApps-headline__category {
    padding: 4px 8px;
  }
  .dApps-headline__info {
    min-height: 120px;
    /* backface-visibility: hidden; */
  }
  .dApps-caption__title {
    margin-top: 10px;
    padding: 0 10px;
  }
}
.dApps-button__item {
  display: flex;
  justify-content: center;
}
.dApps-button__item .dApps-button__tag {
  transform: none;
  position: absolute;
  bottom: 10px;
  padding: 0px 8px;
  border: 2px solid #ce1818;
  border-radius: 8px;
  font-size: 14px;
  color: #ce1818;
}
.dApps-headline__wrapper {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .dApps-headline__wrapper {
    height: 400px;
    margin-bottom: 40px;
  }
  .dApps-headline__item {
    /* margin: 0 10px; */
  }
  .dApps-headline__title {
    /* margin-top: -5px; */
    font-size: 14px;
    line-height: 20px;
  }

  .dApps-image__wrapper {
    min-height: 190px;
  }

  .dApps-image__wrapper img {
    /* height: 268px; */
  }

  .react-multi-carousel-item--active .apps-image__wrapper {
    min-height: 200px;
  }

  .react-multi-carousel-item--active {
    transform: scale(1.1);
    will-change: inherit;
  }
  .react-multi-carousel-item--active {
    margin: -6px 12px 0px 12px;
  }
}

.dApps-headline__info-item {
  background: #ffffff;
  margin-left: 0;
  right: 0;
}
