.popular-Finance__item-ttl {
  color: var(--text-color);
  line-height: normal;
  transition: var(--transiton);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 16px;
}

.popular-Finance__item-ttl:hover {
  opacity: 0.7;
}

.popular-Finance__item-num{
  display: none;
}

.popular-Finance__item {
  padding: 8px 0 8px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center;
}

@media (min-width: 576px) {
  .popular-Finance__item {
    padding: 8px 0 8px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }

  .popular-Finance__item-num {
    margin: auto 0px;
    font-size: 36px;
    font-family: "Open Sans";
    position: relative;
    display: inline-block;
    white-space: nowrap;
    left: -16px;
  }
}
