.ev-pagination{
    position: relative;
}

.ev-pagination .sub-title__wrapper{
    margin-bottom: 8px;
    position: absolute;
}

/* styling tabs */
.ev-pagination .react-tabs{
    position: relative;
    margin-bottom: calc(40px - 16px);
}

.ev-pagination .react-tabs__tab-list{
    border-bottom: none;
    /* text-align: right; */
}

.ev-pagination .react-tabs__tab{
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.ev-pagination .react-tabs__tab a{
    color: inherit;
}

.ev-pagination .react-tabs__tab--selected{
    border:none;
    border-bottom: 4px solid var(--primary-red-color);
}

.ev-pagination .react-tabs__tab--selected a{
    color: var(--primary-red-color);
}

.ev-pagination .tab-content__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media(min-width:590px){
    .ev-pagination .tab-content__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill,  calc((100% - 16px) / 3));
        justify-content: space-between;
        grid-gap: 8px;
    }

    .ev-pagination .tab-content-item__wrapper{
        width:100%;
    } 
}

@media(min-width:768px){
    .ev-pagination .sub-title__wrapper{
        margin-bottom: 16px;
    }

    .ev-pagination .react-tabs__tab{
        font-size: 20px;
        line-height: 24px;
    }

    .ev-pagination .tab-content__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);
        justify-content: space-between;
        grid-gap: 20px;
    }

    .ev-pagination .tab-content-item__wrapper{
        width: 300px;
    } 
}