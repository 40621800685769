.filter-wrapper {
  margin-top: 12px;
  margin-bottom: 32px;
  position: relative;
}

.filter-wrapper__inner {
  position: relative;
}

.header-desk__search-wrapper .input__search-icon,
.filter-wrapper .input__search-icon {
  /* content: url("@assets/images/search-icon-grey.svg"); */
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 10px;
  display: block;
  cursor: pointer;
  padding: 8px;
  box-sizing: content-box;
}

.filter-input {
  width: 100%;
  border: 1px solid var(--line-color);
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding: 8px 45px 8px 16px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  color: inherit;
}

.filter-input:focus {
  outline: none;
  box-shadow: 4px 4px 8px rgba(184, 195, 207, 0.35);
}

.filter-input::placeholder,
.filter-input::-webkit-input-placeholder /* Edge */
.filter-input:-ms-input-placeholder /* IE 10-11 */ {
  color: var(--neutral-color);
}

.suggestion-wrapper {
  position: absolute;
  z-index: 99;
  background: #fff;
  display: block;
  width: 100%;
  border-bottom: 1px solid #d6dee3;
  border-right: 1px solid #d6dee3;
  border-left: 1px solid #d6dee3;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
}
.suggestion-item {
  color: inherit;
  padding: 8px 45px 8px 16px;
  display: block;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 24px;
  color: #aeb6bf;
  transition: var(--transition);
}

.suggestion-item:hover {
  color: inherit;
  background: #d6dee3;
  cursor: pointer;
}
