.popular-finance__container {
  background-color: #BBD7EF;
  border-radius: 20px;
  padding: 10px 20px;
  width: 340px;
  margin-bottom: 20px;
  font-weight: 700;
}

.popular-finance__item:last-child {
  padding-bottom: 0;
}

.finance-image {
  margin-bottom: -19px;
}

.popular-finance__title {
  padding: 0 10px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 18px;
  color: #212936;
}

@media (min-width: 576px) {
  .popular-finance__container {
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }

  .popular-finance__title {
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    margin-left: -8px;
    margin-bottom: 0px;
  }
}
