.vehicle-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vehicle-wrapper__image-wrapper {
    /* flex:1; */
    margin: 5px auto;
    max-width: 520px;
    min-height: 300px;
}

.vehicle-wrapper__image-wrapper img {
    max-height: 436px;
    width: 100%;
}

.vehicle-wrapper__image-wrapper .vehicle-image-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.vehicle-wrapper__image-wrapper .vehicle-info-box {
    border-radius: 10px;
    border: 1px solid #636C77;
    padding: 20px 25px; 
}

.vehicle-wrapper__image-wrapper .vehicle-info-box h1 {
    color: #CE1818;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.vehicle-info-box__brand-link {
    color: #212936;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.vehicle-info-box__vehicle-btn-link {
    border-radius: 4px;
    background: linear-gradient(93deg, #CE1818 32.01%, #FFE660 149.92%);
    width: 120.857px;
    height: 39.73px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.vehicle-info__wrapper {
    max-width: 420px;
    margin: auto;
    margin-left: 15px;
    /* flex:1; */
}

.vehicle-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 8px;
}

.vehicle-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--neutral-color);
    margin-bottom: 16px;
}

.vehicle-info__price-tag {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--text-color);
    margin-left: 8px;
    margin-right: 12px;
}

.vehicle-info__brand-name {
    font-weight: 700;
    margin-left: 8px;
    color: var(--primary-red-color);
}

.vehicle-summary {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
}

.vehicle-summary a {
    color: var(--primary-red-color);
}

.vehicle-info__wrapper .spec-wrapper__spec-item {
    width: 100%;
    background: var(--frame-color);
    position: relative;
    padding: 7px 16px 7px calc(40px + 16px);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
}

.vehicle-info__wrapper .spec-wrapper__spec-item__icon-wrapper {
    background: var(--primary-red-color);
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vehicle-info__wrapper .spec-wrapper__spec-item__icon-wrapper span {
    font-size: 28px;
    padding-bottom: unset;
}

.vehicle-info__wrapper .spec-wrapper__spec-item__icon-wrapper.enlarge-size {
    font-size: 20px;
}

.vehicle-info__wrapper .spec-wrapper__spec-item__icon-wrapper img {
    padding: 8px;
}

.vehicle-info__wrapper .spec-wrapper__spec-item-prop {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
    display: inline-block;
    width: 115px;
}


.vehicle-info__wrapper .spec-wrapper__spec-item-information {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.vehicle-info__wrapper a.spec-wrapper__spec-item-information {
    text-decoration: underline;
}

.social-wrapper {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    color: var(--neutral-color);
}

.social-icon__wrapper {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media(min-width:768px) {
    .vehicle-title {
        font-size: 28px;
        line-height: 38px;
    }

    .vehicle-wrapper__image-wrapper,
    .vehicle-info__wrapper {
        flex: 1;
    }
}

@media(min-width:992px) {}