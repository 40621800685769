.contact-section__container {
    display: flex;
    width: 100%;
}
.static-sidebar {
    flex-shrink: 0;
    flex-basis: 30%;
}
.static-content {
    padding: 10px;
    border-radius: 12px;
    border: 1px solid rgb(116, 113, 113);
    margin: 1rem 0 1rem 2rem;
    width: 100%;
}
.static-content .title {
    font-size: 16px;
}
.static-content .title span {
    padding: 10px 0 0 0;
    font-weight: bold;
}
.static-content .title,
.static-content .content {
    padding: 25px;
}

.content h3 {
    font-size: 16px;
    font-weight: bold;
}
.content p {
    line-height: 1.5rem;
    font-size: 14px;
    margin-bottom: 15px;
}

.contact-us__wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.contact-us__item{
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #636C77;
    border: 1px solid #636C77;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    padding:28px 0;
}

.contact-us__item:hover{
    background: var(--primary-red-color);
    border: none;
    color:white;
}

.contact-us-icon-partnership {
    width: 48px;
    height: 48px;
    display: block;
    background:
       url(@assets/images/static/partnership.svg) center no-repeat;
    background-size: 48px, 48px;
}
.contact-us__item.partnership:hover .contact-us-icon-partnership {
    background: url(@assets/images/static/partnership-white.svg) center no-repeat;
    background-size: 48px, 48px;
}

.contact-us-icon-advertisement {
    width: 48px;
    height: 48px;
    display: block;
    background: url(@assets/images/static/advertisement.svg) center no-repeat;
    background-size: 48px, 48px;
}
.contact-us__item.advertisement:hover .contact-us-icon-advertisement {
    background: url(@assets/images/static/advertisement-white.svg) center no-repeat;
    background-size: 48px, 48px;
}
 
@media only screen and (max-width: 576px) {
    .contact-section__container {
        flex-direction: column;
    }
    .static-content {
        padding: 0;
        border: none;
        margin: 0;
    }
    .static-content .title,
    .static-content .content {
        padding: 0;
        margin-top: 15px;
    }
    .static-content .title h1 {
        font-size: 16px;
    }
    .static-content .title span,
    .static-content .content h3 {
        font-size: 14px;
    }
}