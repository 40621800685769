.banner-mobile__wrapper{
  width: 300px;
  max-height: 100px;
  margin: auto;
  overflow: hidden;
  margin-bottom: 32px;
}

.banner-mobile {
  display: flex;
  margin: auto;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .banner-mobile__wrapper {
    display: none;
  }
}
