.posts-slider_wrapper .slick-disabled {
  display: none !important;
}

.posts-slider_wrapper .slick-list {
  margin: 0 -8px;
}

.posts-slider_wrapper .slick-dots li button:before {
  width: 18px;
  height: 4px;
  border-radius: 3px;
  content: "";
  background: var(--line-color);
  opacity: 1 !important;
}

.posts-slider_wrapper .slick-dots li.slick-active button:before {
  background: var(--primary-red-color);
}

/* .posts-slider_wrapper .slick-slider {
  overflow: hidden;
}

.posts-slider_wrapper .slick-track {
  display: flex;
}

.posts-slider_wrapper .slick-slide {
  width: 23% !important;
} */

.posts-slider_wrapper .slick-next,
.posts-slider_wrapper .slick-prev {
  visibility: hidden;
}

.posts-slider_wrapper .slick-next,
.posts-slider_wrapper .slick-prev {
  height: 100%;
  background: #636c7791;
  width: 48px;
  z-index: 11;
}

.posts-slider_wrapper .slick-next {
  right: 0px;
}

.posts-slider_wrapper .slick-prev {
  left: -1px;
}

.posts-slider_wrapper .slick-next:before {
    content: "\e90c";
    color: #ebeff3;
    font-family: 'icomoon' !important;
    font-size: 40px;
    -webkit-font-smoothing: antialiased;
}

.posts-slider_wrapper .slick-prev:before {
  transform: scaleX(-1);
  display: block;
  content: "\e90c";
  color: #ebeff3;
  font-family: 'icomoon' !important;
  font-size: 40px;
  -webkit-font-smoothing: antialiased;
  
}

.posts-slider_wrapper .slick-next {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.posts-slider_wrapper .slick-prev {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.post-slider__one-slide-img-wrapper{
  margin-bottom: 16px;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  max-height: 147px;
}

.post-slider__one-slide-img {
  object-fit: cover;
}

.post-slider__one-slide-title {
  color: var(--text-color);
  margin-bottom: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.post-slider__one-slide {
  padding: 0 8px;
}

.post-slider__one-slide-img {
  max-width: 100%;
  height: auto;
}

.post-slider__one-slide-title {
  font-size: 13px;
  line-height: initial;
  margin-bottom: 8px;
}

.posts-slider_wrapper .slick-dots {
  bottom: -42px;
}

@media (min-width: 576px) {
  .posts-slider_wrapper .slick-next,
  .posts-slider_wrapper .slick-prev {
    visibility: visible;
  }
  
  .post-slider__one-slide {
    padding: 0 10px;
  }

  .posts-slider_wrapper .slick-list {
    margin: 0 -10px;
  }
  .post-slider__one-slide-title {
    font-size: 16px;
  }

  .post-slider__one-slide-img-wrapper{
    max-height: 160px;
  }
}
