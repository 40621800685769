/*Style variables*/
:root {
  --dark-grey-text-color: #2f2f2f;
  --text-color: #212936;
  --caption-color: #636c77;
  --frame-color: #ebeff3;
  --line-color: #d6dee3;
  --neutral-color: #aeb6bf;
  --primary-red-color: #ce1818;
  --grey: #353d49;
  --gradient-color: linear-gradient(#ce1818 0%, #ffac60 100%);
  --box-shadow-style: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  --transiton: 0.4s ease;
  --transition: 0.4s ease;
  --border-rad: 4px;
  --grey-background: #f2f6f9;
  --light-blue: #d9e2eb;
}

/*Main fonts types*/
.text-large {
  font-weight: 700;
  font-size: 28px;
  line-height: normal;
}

.title-large {
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
}

.title-medium {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.title-small {
  font-weight: 700;
  font-size: 16px;
}

.sub-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.category-small {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.publish-time-text {
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
}

.caption-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.text-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.time-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

.text-bold {
  font-weight: 700;
  font-size: 14px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: var(--text-color);
  word-break: break-word;
  /* overflow-x: hidden; */
}

.main-container {
  max-width: 768px;
  margin: auto;
  box-sizing: content-box;
  padding: 0;
}

main.main-container {
  margin-top: calc(56px + 1rem);
}

.container-common {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 80px 16px 40px 16px;
  overflow: hidden;
}

.container-divided {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.container-divided_aside {
  display: none;
}

.container-full-width {
  margin-top: 20px;
  padding: 0 16px;
}

.container-full-width-gadget {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  max-width: 980px;
}

.page-title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 22px;
}

.page-subtitle {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: initial;
}

.tag-page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 16px;
}

.tag-page-wording {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
}

.btn-red {
  background: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-red-color);
  padding: 10px;
  min-width: 143px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid var(--primary-red-color);
}

.btn-red:hover {
  color: #fff;
  background: var(--primary-red-color);
}

.simple-slider-wrapper {
  margin-bottom: 72px;
}

.placeholder-image__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-section img,
.video-top-block__wrapper img {
  transform: scale(1.03);
}

.video-section__item-img-link {
  overflow: hidden;
  border-radius: 8px;
}

.container-common__wrapper {
  display: flex;
  max-width: 1260px;
  margin: auto;
  align-items: flex-start;
}

.container-common__vertical-banner {
  padding-top: 140px;
  display: none;
}

.input-info-error{
  color: var(--primary-red-color);
}
.desktop-horizontal-banner1{
  display: none;
}

.container-divided_aside-special {
  display: block;
}

@media (min-width: 576px) {
  .video-section img,
  .video-top-block__wrapper img {
    transform: none;
  }

  .video-section__item-img-link {
    border-radius: 0;
  }
}

@media (min-width: 768px) {
  .container-common {
    padding: 24px 16px 34px 16px;
  }
  
  .main-container {
    margin: auto;
    box-sizing: content-box;
    padding: 0 16px 0 16px;
  }

  main.main-container {
    margin-top: 0;
  }

  .container-full-width-gadget {
    padding: 0;
  }

  .desktop-horizontal-banner1 {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 40px;
  }

  .desktop-horizontal-banner2 {
    margin-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .main-container {
    padding: 0 16px;
    max-width: 1180px;
  }

  .container-common {
    /* padding: 0; */
    max-width: 980px;
  }

  .container-divided_aside {
    /* border: 1px solid #a7a7a7; */
    display: block;
    margin-left: 32px;
    width: 300px;
    min-width: 300px;
  }

  .container-divided_aside-special {
    display: block;
    margin-left: 32px;
    width: 300px;
    min-width: 300px;
  }

  .container-divided {
    flex-direction: row;
    justify-content: space-between;
  }

  .container-divided_section {
    width: calc(100% - 300px - 32px);
  }

  .page-title {
    font-size: 40px;
    line-height: 54px;
  }

  .page-subtitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
  }

  .tag-page-title {
    font-size: 28px;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .tag-page-wording {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

@media (min-width: 1400px) {
  .container-common__vertical-banner {
    display: block;
  }
  .container-common {
    padding: 16px 0 34px 0;
  }
}


.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}
.react-tabs__tab--selected {
  background: #fff;
  border-radius: 5px 5px 0 0;
}
.posts-slider_wrapper {
  overflow: hidden;
}

/* Ads */
.ads_slot_top{
  min-width: 320px;
  min-height: 50px;
  text-align: center;
  margin-bottom: 8px;
}

.ads_slot_mobile_300{
  text-align: center;
  width: 100%;
  overflow: auto;
  margin-bottom: 8px;
}

.ad_wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  background-color: #f2f2f2;
}

.ad_wrapper.debug{
  min-height: 66px;
}

.ad_wrapper.desktop{
  display: none;
}

@media (min-width: 768px) {
  .ads_slot_mobile_300{
    display: none;
  }
  .ad_wrapper.mobile{
    display: none;
  }
  .ad_wrapper.desktop{
    display: flex;
  }
}