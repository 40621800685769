.game-item {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    /* line-height: 2rem; */
    border-radius: 15px;
    background: #fff;
}

.game-item div {
    align-self: center;
}

.nft-container {
    padding-top: 60px;
    margin: 0 auto;
}

.nft-container-common {
    margin: 0 auto;
    padding: 0 16px;
}
.nft-container-common .apps-wrapper {
    margin: 0 auto;
}

.nft-container-common .container-divided {
    padding: 0;
}

.nft-container-common .posts-container__outer {
    padding: 0;
}

.nft-container-common .recent-posts__bottom {
    padding: 0;
}

@media (min-width: 768px) {
    .nft-container {
        padding-top: 0;
        margin: 0 auto;
    }
}

@media (min-width: 992px) {

    .nft-container-common {
        margin: 0 auto;
        max-width: 1180px;
    }

    .nft-container-common .apps-wrapper {
        margin: 0 auto;
        max-width: 1034px;
    }

    .nft-container-common .apps-article_wrapper {
        max-width: 1180px;
    }

    .nft-container-common .related-acticle-divided, .recent-acticle-divided {
        max-width: calc(100% - 332px);
    }
}
