.download-status__container{
    border: 1px solid #CE1818;
    max-width: 700px;
    margin: 0 auto 40px;
    padding: 10px 57px;
}

.download-status__text-wrapper{
    margin: auto;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.download-status__restart-download{
    color: inherit;
    text-decoration: underline;
    font-weight: normal;
}

span.query{
    color: #9e9e9e;
}

@media (min-width:768px) {
    .download-status__container{
        padding: 25px 57px;
    }
}