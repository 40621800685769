@media (min-width: 576px) {
  .follow-us-widget__container {
    margin-bottom: 32px;
  }

  .sidebar-widget__container .follow-us-title {
    margin-bottom: 24px;
  }
  .follow-us-social-icons__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .follow-us-social-icons__link {
    width: 40px;
    height: 40px;
    transition: var(--transiton);
    /* display: block; */
    background: white;
    border-radius: 8px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .follow-us-social-icons__link.facebook{
    background: #6078AB;
  }
  .follow-us-social-icons__link.twitter{
    background: #04A9F3;
  }
  .follow-us-social-icons__link.youtube{
    background: #FF3434;
  }
  .follow-us-social-icons__link.instagram{
    background: #FD5365;
  }

  .follow-us-social-icons__link:hover {
    opacity: 0.6;
  }

  .follow-us-social-icons__link img {
    width: 100%;
    height: 100%;
  }
}
