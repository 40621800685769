body{
    overflow-x: hidden;
}

/* section 1 starts */
.adsform-top{
    margin-top: 1px;
    position: relative;
    height: 960px;
    background-color: #282c35;
}

.adsform-section2{
    margin-top: 1px;
    position: relative;
    height: 1450px;
}
.adsform-section3{
    margin-top: 1px;
    position: relative;
    height: 875px;
}
.adsform-section6{
    background-color: #212936;
    margin-top: 1px;
    position: relative;
    height: 750px
}

.adsform-image{
    padding:0;
    margin:0;
    /* height: 760px; */
}
.adsform-image-section2{
    padding:0;
    margin:0;
}
.adsform-image-section6{
    padding:0;
    /* margin:0; */
    margin-top:-70px;
}

.adsform-image__wrapper.mobile{
    display: block;
}

.adsform-image__wrapper.desktop{
    display: none;
}

.adsform-image__wrapper.bigger{
    display: none;
}

.ads-container-full-width.description{
    text-align: center;
}
.adsform-top .ads-section-two__wrapper{
    width: 100%;
}
.ads-container-full-width.services{
    text-align: center;
}
.ads-container-full-width.format{
    text-align: center;
}

.adsform-title{
    color: var(--primary-red-color);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #CE1818;
    margin-bottom: 4px;
}

.adsform-logo__wrapper{
    text-align: center;
    width: 100%;
    margin-top: 60px;
}
.adsform-logo__wrapper img{
    width: 100%;
}

.adsform-subtitle{
    font-weight: normal;
    font-size: 18px;
    /* line-height: 22px; */
    /* margin-bottom: 12px; */
    color: #fff;
    text-align: center;
}

.adsform-red-btn{
    display: block;
    margin:0 auto 32px;
    background: #CE1818;
    border: 1px solid #CE1818;
    box-sizing: border-box;
    border-radius: 80px;
    width: 195px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 0;
}

.adsform-scroll__wrapper{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #CE1818;
    text-align: center;
}

.adsform-top .ads-container-full-width.scroll{
    margin-bottom: 60px;
}

.form-card-header{
    background-color: #ce372c;
    width: 90%;
    height: 92px;
    margin: 20px auto 0px auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.form-card-body{
    background-color: #fff;
    width: 90%;
    height: 648px;
    margin: 0px auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.card-header-text{
    color: #fff;
    font-size: 16px;
}

.adsform-top .ads-section-one__wrapper{
    width: 300px;
}

/* section 2 starts*/
.adsform-bg-img.desktop{
    display: none;
}
.ads-container-full-width.statistic{
    padding: 35px 0px;
}

.ads-statistic__container{
    display: flex;
    flex-direction: row;
}

.ads-statistic-first-col{
    width: 50%;
}
.ads-statistic-second-col{
    width: 50%;
}

.ads-statistic__wrapper{
    text-align: center;
    margin-bottom: 0px;
    flex: 50%;
    padding: 5px;
}

.ads-statistic__wrapper:last-child{
    margin-bottom: 0;
}

.statistic-title{
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}

.ads-statistic-number{
    padding-top: 20px;
    font-weight: bold;
    font-size: 64px;
    color: #FFFFFF;
    height: 80px;
}

/* section 3 starts */

.adsform-brand__image-wrapper{
    display: none;
}

.ads-container-full-width.brand{
    padding: 0 20px;
    height: 630px;
}

.brand-description__container{
    text-align: center;
}

.brand-section__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #CE1818;
    margin: 24px 0px;
}

.brand-section__subtitle{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #212936;
    margin-bottom: 56px;
}

.brand__wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;    
    margin: auto;
}
.ads-brand-sub{
    width: 100%;
    margin-bottom: 20px;
}

.brand__icon{
    margin-bottom: 52px;
}

.brand__icon:nth-last-of-type(2){
    margin-bottom:0;
}

.brand__icon:last-of-type{
    margin-bottom:0;
}

.ads-container-full-width.work-together{
    color:#fff;
    text-align: center;
}

.ads-work-together__title{
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
    padding: 32px 0px;
}

.work-together__subtitle{
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.work-together__social{
    width: 216px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.ads-work-together__social-wrapper{
    color: white;
    font-size: 24px;
}

.ads-work-together__email-wrapper{
    color: #FFFFFF;
}

.adsform-form__wrapper{
    text-align: left;
    margin: 0px auto;
    max-width: 464px;
    width: 90%;
    padding-top: 20px;
}

.ads-jt-form-label{
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #AEB6BF;
    margin-bottom: 4px;
    margin-top:15px;
}
.ads-jt-form-label::after{
    content: '*';
    color: red;
}

input.ads-jt-form{
    width: 100%;
    background: inherit;
    border: none;
    border-bottom: 1px solid #AEB6BF;
    color:black;
    margin-bottom: 28px;
}

select.ads-jt-form{
    width: 100%;
    background: inherit;
    border: none;
    border-bottom: 1px solid #AEB6BF;
    color:#AEB6BF;
    margin-bottom: 58px;
}

.select-placeholder{
    color:#AEB6BF; 
}

.ads-jt-form::placeholder{
    color:#AEB6BF;
}

input[required]+label {
    color: #AEB6BF;
    position: relative;
    top:-56px;
}

#category-form{
    margin-top:-45px;
    margin-bottom: 40px;
}
#category-label{
    color: #AEB6BF;
    position: relative;
    top: -45px;
    left: 4px;
    font-size: 10px;
}
#category-label:after{
    content: '*';
    color: red;
}
#category-form:invalid+#category-label{
    display: inline-block;
}
  
input[required]+label:after {
    content: '*';
    color: red;
}

.required:after {
    content:" *";
    color: red;
}
    
/* show the placeholder when input has no content (no content = invalid) */ 
input[required]:invalid+label {
    display: inline-block;
}
  
/* hide the placeholder when input has some text typed in */  
input[required]:valid+label {
    visibility: hidden;
}

input.ads-jt-form:focus{
    outline:none;
}

.ads-btn-send-msg{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #212936;
    background: #AEB6BF;
    border:none;
    padding: 8px 15px;
    margin:auto;
    display: block;
    width: 90%;
}
.ads-btn-send-msg-bottom{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #212936;
    background: #AEB6BF;
    border:none;
    padding: 16px 30px;
    margin-top: 30px;
    display: block;
}

.adsform-red-bg{
    background: var(--primary-red-color);
    position: relative;
    height: 631px;
}

.adsform-grey-bg{
    position: relative;
}

.adsform-input__wrapper{
    margin-bottom: -28px;
}

.ads-input-info-error{
    position: relative;
    top: -55px!important;
    color: var(--primary-red-color);
    height: 10px;
    font-size: 15px;
}

.ads-big-title{
    color: #fff;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 10px;
}
.ads-title-sec2{
    color: #212936;
    font-size: 32px;
    font-weight: bold;
    margin: 35px 0px;
}
.service-bg{
    border-radius: 10px;
    background-color: #D6DEE3;
    width: 100%;
    height: 100%;
    padding: 25px 0px;
}
.service-title{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #212936;
    color:#fff;
    font-weight: bold;
    font-size: 30px;
    padding-left: 30px;
    width: 80%;
    height: 66px;
    display: flex;
    align-items: center;
}
.service-list{
    margin-top: 20px;
    padding-left: 30px;
    width: 90%;
    text-align: left;
}
.ads-list-text{
    padding-left: 1.5em;
    text-indent: -24px;
}
.ads-list-title{
    margin-top: 18px;
}
.ads-btn-rate{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px;
    border-color: white;
    width: 169px;
    height: 44px;
    margin-top: 40px;
    background-image: linear-gradient(to right, #CE1818, #FFAC60);
}

.ads-slide-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
}
.ads-slide-item{
    padding: 15px;
    color: #636C77;
}
.ads-sub-title{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
.ads-slide-body{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 80%;
}
.ads-slide-body__wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.ads-slide__wrapper{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
}
.ads-slide-banner{
    margin-top: 20px;
    width: 90%;
}
.ads-slide-banner-mobile{
    margin-top: 20px;
    height: 217px;
}
.ads-slide-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #EBEFF3;
    border-radius: 10px;
    width: 90%;
    height: auto;
    padding: 20px;
    text-align: left;
}

.ads-slide-icon-left{
    background-color: #a7a9af;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 30px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items:center;
}
.ads-slide-icon-right{
    background-color: #a7a9af;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 30px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items:center;
}

.ads-btn-pagination{
    background: rgba(76, 175, 80, 0);
    border: 0px;
}

.ads-input__wrapper{
    display: flex;
    justify-content: flex-start;
}

.ads-btn-banner{
    background: rgba(76, 175, 80, 0);
    border: 0px;
    font-weight: bold;
    height: 50px;
    color: #636C77;
}

.ads-active{
    color: red;
    border-bottom: red 3px solid;
}

.ads-tnc{
    font-size: 10px;
    color: #AEB6BF;
    line-height: 15px;
    margin-top: -50px;
    margin-bottom: 20px;
}
.ads-tnc a{
    color:red;
}
#ads-h1{
    font-size: 0px;
}

@media (min-width:768px) {
    .adsform-image__wrapper.mobile{
        display: none;
    }

    .adsform-image{
        padding:0;
        margin:0;
    }

    .ads-container-full-width.description{
        text-align: left;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
    }
    .ads-container-full-width.services{
        text-align: left;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
    }
    .ads-container-full-width.format{
        text-align: center;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
    }

    .adsform-top .ads-section-one__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 400px;
        padding-left: 30px;
    }

    .adsform-top .ads-section-two__wrapper{
        width: 400px;
    }

    .adsform-top .adsform-logo__wrapper{
        margin: 0 0 16px 0;
    }

    .adsform-top .adsform-subtitle{
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 32px;
    }

    .adsform-top .ads-container-full-width.scroll{
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
    }

    /* section two */
    .adsform-red-bg{
        background: transparent;
        height: 540px;
    }

    .adsform-bg-img.desktop{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .adsform-bg-img.desktop img{
        object-fit: cover;
    }

    .ads-container-full-width.statistic{
        width: 100%;
        max-width: 1250px;
        margin:auto;
    }

    .ads-statistic__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 560px;
        justify-content: space-between;
        margin: auto;
    }

    .ads-statistic__wrapper{
        padding: 0px 35px;
        text-align: left;
        width: 240px;
    }

    /* section four */
    .ads-container-full-width.work-together{
        text-align: left;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
    }
    .work-together__wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 992px;
        margin: 0px auto;
    }

    .work-together__social{
        margin-left: 0;
    }
    .work-together__item{
        width: 45%;
    }

    /* NEW CUSTOM CSS */
    .adsform-top{
        height: 610px;
    }
    .form-card-header{
        height: 50px;
    }
    .card-header-text{
        font-size: 13px;
        line-height: 16px;
    }

    input.ads-jt-form{
        margin-bottom: 10px;
    }

    input[required]+label {
        top:-36px;
    }
    select.ads-jt-form{
        margin-bottom: 30px;
    }
    .ads-tnc{
        margin-top: -35px;
    }
    .form-card-body{
        height: 520px;
    }
    .adsform-section2{
        height: 800px;
    }
    .service-bg{
        padding: 15px 0px;
    }
    .service-title{
        font-size: 24px;
        padding-left: 20px;
        width: 90%;
        height: 56px;
    }
    .service-list{
        padding-left: 20px;
    }
    .ads-list-text{
        padding-left: 1.5em;
        text-indent: -24px;
    }
    .ads-list-title{
        margin-top: 18px;
    }
    .ads-slide-banner{
        width: 100%;
    }
    .adsform-section6{
        height: 400px
    }
}

@media (min-width:992px) {
    .adsform-image__wrapper.desktop{
        display: block;
    }

    .adsform-top{
        height: 810px;
    }

    .adsform-section2{
        height: 800px;
    }

    .adsform-section3{
        height: 800px;
    }
    .adsform-section6{
        padding-top: 0px;
        background: transparent;
        height: 100%;
    }

    .ads-container-full-width.description{
        max-width: 1390px;
        padding-top: 0px;
    }
    .ads-container-full-width.services{
        max-width: 1300px;
    }
    .ads-container-full-width.format{
        max-width: 1300px;
    }

    .adsform-top .ads-section-one__wrapper{
        width: 600px;
        padding-left: 66px;
    }

    .adsform-top .ads-section-two__wrapper{
        width: 600px;
        padding-left: 30px;
        margin-top: 15px;
    }

    .adsform-top .adsform-title{
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
    }

    .adsform-top .adsform-logo__wrapper{
        margin: 0 0 0 0;
    }
    
    .adsform-top .adsform-subtitle{
        margin-bottom: 64px;
        width: 618px;
    }
    .adsform-top .ads-container-full-width.scroll{
        position: absolute;
        bottom: 80px;
    }

    .ads-statistic__container{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 50px;
        margin-left: unset;
        margin-right: unset;
        margin-bottom: unset;
    }

    /* section three */
    .ads-container-full-width.brand{
        width: 100%;
        max-width: 1180px;
        height: 631px;
        margin: auto;
        position: relative;
    }
    .adsform-brand__image-wrapper{
        display: block;
        position: absolute;
        width: 420px;
        height: 249px;
        right: -120px;
        bottom: 0;
    }

    .brand-description__container{
        width: 100%;
        text-align: center;
    }

    .brand-section__title{
        margin-top: 50px;
        font-size: 62px;
        line-height: 74px;
    }

    .brand-section__subtitle{
        width: 100%;
        padding: 0px 350px;
    }

    .brand__wrapper{
        width: 100%;
        margin: auto;
    }
    
    .brand__icon{
        margin-bottom: 40px;
    }
    .brand__icon:nth-last-of-type(2){
        margin-bottom:40px;
    }
    
    .brand__icon:last-of-type{
        margin-bottom:40px;
    }

    .ads-brand-testimoni{
        margin-top: 20px;
        width:225px;
        margin-left: auto;
        margin-right: auto;
    }

    /* NEW CUSTOM CSS */
    .form-card-header{
        width: 547px;
        height: 92px;
    }
    
    .form-card-body{
        width: 547px;
        height: 650px;
    }
    
    .card-header-text{
        font-size: 18px;
    }

    .adsform-form__wrapper{
        width: 100%;
        padding-top: 20px;
    }
    .adsform-form__wrapper#formadsform{
        padding-top: 0px;
    }

    .ads-btn-send-msg{
        width: 464px;
        padding: 16px 30px;
    }

    .ads-title-sec2{
        font-size: 64px;
        margin: 60px 0px;
    }

    .ads-slide-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap:40px;
        padding-top: 40px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ads-slide-body__wrapper{
        width: 90%;
        margin: 0px auto;
    }

    .ads-slide__wrapper{
        display: flex;
        flex-wrap: wrap;
        gap:40px;
        padding-top: 0px;
    }

    .ads-slide-text{
        width: 528px;
        height: 334px;
        padding: 30px;
    }

    .adsform-red-bg{
        height: 575px;
    }

    .adsform-image-section2{
        height: 880px;
    }

    .ads-container-full-width.statistic{
        padding: 55px 0px;
    }

    .ads-statistic__wrapper{
        margin-bottom: 46px;
        flex: 25%;
    }

    .service-bg{
        padding-top: 35px;
        width: 402px;
        height: 527px;
    }

    .service-title{
        font-size: 36px;
        padding-left: 37px;
        width: 300px;
        height: 66px;
    }
    .service-list{
        padding-left: 37px;
        width: 400px;
    }
    .ads-list-text{
        padding-left: 1.5em;
        text-indent: -24px;
    }
    .ads-list-title{
        margin-top: 18px;
    }

    .ads-sub-title{
        font-size: 36px;
        margin: 20px 0px;
    }

    .ads-slide-banner{
        width: 408px;
        height: 334px;
    }

    .ads-slide-banner-mobile{
        display: flex;
        justify-content: center;
        height: 334px;
    }
    .ads-big-title{
        padding-top: 50px;
        font-size: 64px;
        margin-bottom: 0px;
    }

    .statistic-title{
        margin-top: 0px;
        font-size: 16px;
        line-height: 22px;
        height: 30px;
    }
    
    .ads-statistic-number{
        font-size: 100px;
        line-height: 100px;
        padding-top: 0px;
        margin-bottom: 20px;
    }

    .ads-statistic-first-col{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    .ads-statistic-second-col{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .ads-brand-sub{
        width: 300px;
        margin-bottom: 0px;
    }

    .ads-work-together__title{
        font-size: 62px;
        line-height: 60px;
        margin-bottom: 32px;
        margin-top: 0px;
    }

    input.ads-jt-form{
        width: 100%;
        background: inherit;
        border: none;
        border-bottom: 1px solid #AEB6BF;
        color:black;
        margin-bottom: 28px;
    }
    
    select.ads-jt-form{
        margin-bottom: 58px;
    }
    
    input[required]+label {
        top:-56px;
    }
    .ads-tnc{
        margin-top: -50px;
    }
    .ads-container-full-width.work-together{
        padding: 0px;
    }
    .work-together__wrapper{
        margin: 60px auto;
    }
}

@media (min-width:1441px) {
    .adsform-image__wrapper.desktop{
        display: none;
    }
    .adsform-image__wrapper.bigger{
        display: block;
    }
    .adsform-section6{
        height: 300px;
    }
}