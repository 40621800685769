.editors-pick__title {
  /* background: url("@assets/images/submenu-icon.svg") no-repeat; */
  /* margin-left: 32px; */
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.editors-pick__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.editors-pick__item {
  background: var(--frame-color);
  border-radius: 8px;
  overflow: hidden;
}

.editors-pick__container {
  /* margin-bottom: 32px; */
}

.editors-pick__wrapper .editors-pick__item {
  margin-bottom: 16px;
}

.editors-pick__image-wrapper {
  width: 100%;
  display: grid;
}

.editors-pick__image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editors-pick__info-wrapper {
  padding: 12px;
}

.editors-pick__info-title {
  color: var(--dark-grey-text-color);
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 8px;
  overflow: hidden;
}

.editors-pick__info-title-link {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.editors-pick__info-category {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.editors-pick__info-category-link {
  color: var(--primary-red-color);
  text-transform: capitalize;
  /* transition: var(--transiton); */
}
.editors-pick__info-category-link:hover{
  text-decoration: underline;
}

.editors-pick__info-title-link {
  color: var(--text-color);
}

/* .editors-pick__info-title-link:hover {
  opacity: .7;
} */

@media (min-width: 576px) {
  .editors-pick__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  .editors-pick__info-title-link {
    color: var(--frame-color);
    transition: var(--transiton);
  }

  .editors-pick__item {
    min-height: 150px;
    border-radius: 8px;
    color: white;
    position: relative;
    /* width: calc((100% - 16px) / 3); */
  }

  .editors-pick__image-wrapper {
    height: 100%;
  }

  .editors-pick__image-wrapper::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(47, 47, 47, 0) 0%,
      rgba(47, 47, 47, 0.51) 51.04%,
      var(--dark-grey-text-color) 77.6%
    );
  }

  .editors-pick__item:hover .editors-pick__image-wrapper::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(206, 24, 24, 0) 0%,
      rgba(206, 24, 24, 0.24) 51.04%,
      var(--primary-red-color) 80.73%
    );
  }

  .editors-pick__item:hover .editors-pick__info-category-link {
    color: #ffea60;
  }

  .editors-pick__info-wrapper {
    padding: 0 16px;
    position: absolute;
    bottom: 8px;
  }

  .editors-pick__info-title {
    margin-bottom: 8px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .editors-pick__info-category {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 768px) {
  .editors-pick__item {
    margin-bottom: 16px;
    height: 250px;
  }
}

@media (min-width: 992px) {
  .editors-pick__title {
    font-weight: 700;
    font-size: 24px;
  }

  .editors-pick__wrapper {
    max-width: 880px;
  }

  /* .editors-pick__item {
    width: calc((100% - 40px) / 3);
  } */

  .editors-pick__info-wrapper {
    bottom: 20px;
  }

  .editors-pick__info-title {
    font-size: 20px;
    line-height: 24px;
  }

  .editors-pick__info-category {
    font-size: 16px;
    line-height: 24px;
  }
}
