.infinite-scroll-block .desktop-banner__wrapper {
  margin-bottom: 32px;
}

#load-more-btn {
  border: none;
  border-radius: 8px;
  padding: 5px 25px;
  background: linear-gradient(93.14deg, #CE1818 32.01%, #FFE660 149.92%);
  color: #fff;
  font-weight: 600;
  margin: 20px auto;
  width: 235.94px;
  height: 39.73px;
}

#load-more-btn:hover {
  background-color: #d35b5b;
}