/* section 1 starts */
.partnership-top{
    margin-top: 1px;
    position: relative;
}

.partnership-image{
    padding:0;
    margin:0;
    margin-bottom: -80px;
}

.partnership-image__wrapper.mobile{
    display: block;
}

.partnership-image__wrapper.desktop{
    display: none;
}

.container-full-width.description{
    text-align: center;
}

.partnership-title{
    color: var(--primary-red-color);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #CE1818;
    margin-bottom: 4px;
}

.partnership-logo__wrapper{
    text-align: center;
    margin: 0 auto 24px;
    width: 240px;
    height: 65px;
}
.partnership-logo__wrapper img{
    width: 100%;
}

.partnership-subtitle{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
}

.partnership-red-btn{
    display: block;
    margin:0 auto 32px;
    background: #CE1818;
    border: 1px solid #CE1818;
    box-sizing: border-box;
    border-radius: 80px;
    width: 195px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 0;
}

.partnership-scroll__wrapper{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #CE1818;
    text-align: center;
}

.partnership-top .container-full-width.scroll{
    margin-bottom: 60px;
}

/* section 2 starts*/
.partnership-bg-img.desktop{
    display: none;
}
.container-full-width.statistic{
    padding: 100px 0px;
}

.statistic__container{
    display: flex;
    flex-direction: column;
}

.statistic__wrapper{
    text-align: center;
    margin-bottom: 56px;
}

.statistic__wrapper:last-child{
    margin-bottom: 0;
}

.statistic-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

.statistic-number{
    font-weight: bold;
    font-size: 100px;
    line-height: 136px;
    color: #FFFFFF;
}

/* section 3 starts */

.partnership-brand__image-wrapper{
    display: none;
}

.container-full-width.brand{
    padding: 0 20px;
    margin: 100px auto;
}

.brand-description__container{
    text-align: center;
}

.brand-section__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #CE1818;
    margin-bottom: 24px;
}

.brand-section__subtitle{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #212936;
    margin-bottom: 56px;
}

.brand__wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;    
    width: 264px;
    margin: auto;
}

.brand__icon{
    margin-bottom: 52px;
}

.brand__icon:nth-last-of-type(2){
    margin-bottom:0;
}

.brand__icon:last-of-type{
    margin-bottom:0;
}

.container-full-width.work-together{
    background: var(--text-color);
    color:#fff;
    padding: 100px 40px;
    text-align: center;
}

.work-together__title{
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 32px;
}

.work-together__subtitle{
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.work-together__social{
    width: 216px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.work-together__social-wrapper{
    color: white;
    font-size: 24px;
}
.work-together__email-wrapper{
    font-weight: 300;
    font-size: 32px;
    line-height: 26px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 64px;
}

.partnership-form__wrapper{
    text-align: left;
}

.partnership-input__wrapper{
    margin-bottom: 40px;
}

.jt-form-label{
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #AEB6BF;
    margin-bottom: 4px;
}

input.jt-form{
    width: 100%;
    background: inherit;
    border: none;
    border-bottom: 1px solid #AEB6BF;
    color:white;
}

input.jt-form:focus{
    outline:none;
}

.btn-send-msg{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #212936;
    background: #AEB6BF;
    border:none;
    padding: 16px 30px;
    margin:auto;
    display: block;
}

.partnership-red-bg{
    background: var(--primary-red-color);
    position: relative;
}

.partnership-grey-bg{
    background: var(--text-color);
}


@media (min-width:768px) {
    .partnership-image__wrapper.mobile{
        display: none;
    }

    .partnership-image__wrapper.desktop{
        display: block;
    }

    .partnership-image{
        padding:0;
        margin:0;
    }

    .container-full-width.description{
        text-align: left;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1180px;
        margin-top: 40px;
        left: 50%;
        transform: translateX(-50%);
    }

    .partnership-top .section-one__wrapper{
        width: 400px;
    }

    .partnership-top .partnership-logo__wrapper{
        margin: 0 0 16px 0;
    }

    .partnership-top .partnership-subtitle{
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 32px;
    }

    .partnership-top .container-full-width.scroll{
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
    }

    /* section two */
    .partnership-red-bg{
        background: transparent;
    }

    .partnership-bg-img.desktop{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .partnership-bg-img.desktop img{
        object-fit: cover;
    }

    .container-full-width.statistic{
        width: 100%;
        max-width: 992px;
        margin:auto;
    }

    .statistic__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 560px;
        justify-content: space-between;
        margin: auto;
    }

    .statistic__wrapper{
        text-align: left;
        width: 240px;
    }

    /* section four */
    .container-full-width.work-together{
        text-align: left;
    }
    .work-together__wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 992px;
        margin: auto;
    }

    .work-together__social{
        margin-left: 0;
    }
    .work-together__item{
        width: 45%;
    }
}

@media (min-width:992px) {
    .container-full-width.description{
        max-width: 1180px;
        margin-top: 80px;
    }

    .partnership-top .section-one__wrapper{
        width: 457px;
    }

    .partnership-top .partnership-title{
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
    }

    .partnership-top .partnership-logo__wrapper{
        margin: 0 0 32px 0;
    }
    
    .partnership-top .partnership-subtitle{
        margin-bottom: 64px;
    }
    .partnership-top .container-full-width.scroll{
        position: absolute;
        bottom: 80px;
    }

    .statistic__container{
        margin: unset;
    }

    /* section three */
    .container-full-width.brand{
        width: 100%;
        max-width: 1180px;
        margin: 100px auto;
        position: relative;
    }
    .partnership-brand__image-wrapper{
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .brand-description__container{
        width: 710px;
        text-align: left;
    }

    .brand-section__title{
        font-size: 62px;
        line-height: 74px;
    }

    .brand-section__subtitle{
        width: 495px;
    }

    .brand__wrapper{
        width: 280px;
        margin: unset;
    }
    
    .brand__icon{
        margin-bottom: 40px;
    }
    .brand__icon:nth-last-of-type(2){
        margin-bottom:40px;
    }
    
    .brand__icon:last-of-type{
        margin-bottom:40px;
    }

}

@media (min-width:1180px) {
    .brand__wrapper {
        width: 495px;
    }
}