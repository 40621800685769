.affiliate__container {
  margin-bottom: 24px;
}

.affiliate__title {
  padding: 0;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  color: #212936;
}

.affiliate__list {
  transition: all 0.5s;
}

.affiliate__list.collapsed{
  max-height: 300px;
}

.affiliate__top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
}

.affiliate__expand-button {
  color: rgb(206, 24, 24);
  font-weight: 600;
  font-size: 16px;
  border: none;
  background: none;
}

@media (min-width: 768px) {
  .affiliate__top-wrapper {
    margin: 0 0 18px 0;
  }
  .affiliate__title {
    line-height: 32px;
    align-items: center;
  }
}
