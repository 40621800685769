.most-read-posts_wrapper {
  background: var(--text-color);
  padding: 29px 24px 50px 24px;
  max-width: 100%;
  height: 300px;

  /* background-image: linear-gradient(to bottom, #ce1818, #ba0045, #94195f, #662d66, #3c315a, #2e3050, #252d43, #212936, #212936, #212936, #212936, #212936); */
  background-color: #212936;
  border-radius: 8px;
  /* background-image: linear-gradient(to bottom, #ce1818, #ba0045, #94195f, #662d66, #3c315a, #2e3050, #252d43, #212936, #212936, #212936, #212936, #212936); */
}

.most-read-posts_wrapper .post-slider__one-slide-title {
  color: var(--frame-color);
}

.most-read-posts_wrapper .sub-title__wrapper {
  margin-bottom: 26px;
}
@media (min-width: 992px) {
  .most-read-posts_wrapper {
    max-width: 880px;
    height: 340px;
  }
}

.most-read-posts_wrapper .icon-icon-double-arrow:before {
  color: white !important;
}

.most-read-posts_wrapper .post-info__publish-date {
  color: #afafaf !important;
}
